import React, { Component } from "react";
// import Particles from "react-particles";
// import { loadFull } from "tsparticles";
// import PageHelmet from "../component/common/Helmet";
import Header from "../component/header/Header";
import BrandTwo from "../elements/BrandTwo";
import FooterTwo from "../component/footer/FooterTwo";
import FooterFloat from "../component/footer/FooterFloat";
import { FcDataProtection } from "react-icons/fc";
import {FiMail , FiSmartphone, FiCheck, FiClock, FiUser, FiMonitor, FiRadio, FiDownload, FiVideo, FiCheckCircle} from "react-icons/fi";
import { FaGraduationCap, FaWhatsapp, FaPhone, FaRunning, FaEye, FaCreditCard, FaPaypal } from "react-icons/fa";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from "react-bootstrap/Modal";
import ReactCountryFlag from "react-country-flag";
import Selectrix from "react-selectrix";
import moment from 'moment';
import TestimonialOne from "../blocks/testimonial/TestimonialOne";
import ReactPlayer from "react-player";
import Typical from 'react-typical';
import { GoRocket } from "react-icons/go";
import Spinner from 'react-bootstrap/Spinner';
import { MdWork, MdOndemandVideo, MdHelp, MdEmail } from "react-icons/md";
import { AiOutlineDown, AiOutlineRight, AiOutlineClose } from "react-icons/ai";
import ModalVideo from 'react-modal-video';
import TeamFull from "../elements/Team_full";
import { BsCircle, BsArrowLeftCircle, BsLightningFill } from "react-icons/bs";
import {AccordionWrapper, AccordionItem} from "custom-react-accordion";
import 'custom-react-accordion/dist/Accordion.css'
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import {
    Accordion,
    AccordionItem as AccordionItemZero,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton,
} from 'react-accessible-accordion';
import 'react-tabs/style/react-tabs.css';
import PlataformaAWS from "../component/dojopy/PlataformaAWS";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import FlipCountdown from '@rumess/react-flip-countdown';
import { GiPlatform } from "react-icons/gi";
import PreguntasFlex from "../component/dojopy/PreguntasFlex";


const InfoAcordeon = {
    intro: "Introducción a Amazon Web Services es una colección de servicios de computación en la nube pública que en conjunto forman una plataforma de computación en la nube, ofrecidas a través de Internet por Amazon.com",
    iam: "AWS Identity and Access Management (IAM) le permite la administración de identidades y el acceso a los servicios y recursos de AWS de manera segura.    ",
    s3: "S3 aumenta la rentabilidad, aplica seguridad y satisface los requisitos de cumplimiento. Vea el uso de almacenamiento y las tendencias de actividad de toda la organización.    ",
    cloudfront: "Amazon CloudFront es un servicio de red de entrega de contenido (CDN) creado para ofrecer un alto rendimiento, seguridad y comodidad para los desarrolladores.    ",
    ec2: "Cree y administre sitios web, aplicaciones o procesos en la red segura y fiable de AWS. Abra una cuenta gratuita y conozca los servicios y la potencia de computación de AWS.    ",
    rds: "Amazon Relational Database Service (Amazon RDS) es una colección de servicios administrados que facilita las tareas de configuración, operación y escalado.    ",
    serverless: "AWS ofrece tecnologías para ejecutar código, administrar datos e integrar aplicaciones, todo sin administrar servidores.",
    route53: "Amazon Route 53 conecta de forma efectiva las solicitudes del usuario con la infraestructura en ejecución en AWS, como instancias de Amazon EC2, equilibradores.    ",
    amplify: "AWS Amplify es un conjunto de herramientas y características creadas específicamente para que los desarrolladores web y móviles de frontend puedan crear.    ",
    ses: "Amazon Email Service (SES) es un servicio de correo electrónico rentable, Flexible y escalable que permite a los desarrolladores enviar correos desde ..    ",
    docker: "Un contenedor de Docker es una imagen de Docker instanciada (en ejecución). AWS proporciona Amazon Elastic Container Registry (ECR).    ",
    project: "Genial! cuando llegues a este módulo tendras las skills necesarias para montar un Proyecto completo de Ecommerce en la nube de AWS.",
}

const KeyCountry =  [
    {key: "PE", label: "Perú"},
    {key: "MX", label: "México"},
    {key: "BO", label: "Bolivia"},
    {key: "EC", label: "Ecuador"},
    {key: "PR", label: "Puerto Rico"},
    {key: "SV", label: "El Salvador"},
    {key: "CL", label: "Chile"},
    {key: "CR", label: "Costa Rica"},
    {key: "HN", label: "Honduras"},
    {key: "ES", label: "España"},
    {key: "UY", label: "Uruguay"},
    {key: "CO", label: "Colombia"},
    {key: "GT", label: "Guatemala"},
    {key: "PA", label: "Panamá"},
    {key: "NI", label: "Nicaragua"},
    {key: "PY", label: "Paraguay"},
    {key: "DO", label: "República Dominicana"},
    {key: "BR", label: "Brasil"},
    {key: "US", label: "Estados Unidos"},
    {key: "CA", label: "Canada"},
]


const Turnos = [
    {key: "noche", label: "Noche"},
    // {key: "dia", label: "Mañana"},
]


class LandingAWS extends Component{
    constructor () {
        super()
        this.state = {
            DataFechas: [],
            fechaLimite: "",
            bootcampSlug: "cloud-aws",
            fecha_start_end: "",
            PriceEspecialBody: "",
            isPriceEspecial: false,
            isPricePais: false,
            show_pricing: false,
            utc_offset: '-0500',
            Modalidad: "",
          isOpen: false,
          isOpenTemario: false,
          isOpenResume: false,
          isOpen2: false,
          isOpenVideo: false,
          Email: '',
          countryCode: 'US',
          utc_dif: 0,
          price_contado: "",
          price_contado_regular: "",
          ticket_type: '',
          colorTicket1: 'black',
          colorTicket2: 'black',
          colorTicket3: 'black',
          cupon: '',
          MesAcceso: null,
          loadingEnroll: false,
          VideoPitch: false
      }

      
        this.openModal = this.openModal.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onSubmit2 = this.onSubmit2.bind(this);
        this.openModal2 = this.openModal2.bind(this);
        this.openModalVideo = this.openModalVideo.bind(this);
        this.openModalEmail = this.openModalEmail.bind(this);
        this.openModal4 = this.openModal4.bind(this);
        // this.FechaEnroll = this.FechaEnroll.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.OpenWS = this.OpenWS.bind(this);
        this.DownloadPdf = this.DownloadPdf.bind(this);
        this.GetLinkWS = this.GetLinkWS.bind(this);
        this.DynamicCountry = this.DynamicCountry.bind(this);

        // window.addEventListener('load', function() {
        //     document.title = 'Bootcamp Desarrollo Cloud Amazon Web Services 100% online en vivo';
        // })
    }

    
// async customInit(engine) {
//     // this adds the bundle to tsParticles
//     await loadFull(engine);
//     }

// async particlesLoaded(container) {
//     console.log(container);
// };


GetLinkWS(){
        if (window.type_device){
            return "https://api.whatsapp.com/send?phone=51935489552&text=Hola,%20informes%20sobre%20el%20Bootcamp%20Cloud%20de%20AWS";
        } else {
            return "https://web.whatsapp.com/send?phone=51935489552&text=Hola,%20informes%20sobre%20el%20Bootcamp%20Cloud%20de%20AWS";
        }
    }

 
    DynamicCountry(country=''){
        const beca = window.location.search.split('codigo=')[1];


        const newCountry = country || this.state.countryCode;
        
        if (country){
            this.setState({countryCode: country});
        }


        let configPrice1 = 'cloud-aws';


        this.setState({
            isPricePais: false,
            utc_offset: '-0500',
            utc_dif: 0,
            DataFechas: this.state.pricingAPI[configPrice1]['fechas'],
            isPriceEspecial: this.state.pricingAPI[configPrice1]['isPriceEspecial'],
            PriceEspecialBody: this.state.pricingAPI[configPrice1]['PriceEspecialBody'],
            fechaLimite: this.state.pricingAPI[configPrice1]['fechaLimite'],
            fecha_start_end: this.state.pricingAPI[configPrice1]['fecha_start_end'],
            // price_cuota: this.state.pricingAPI[configPrice2]['price']['usd']['price_view_base'], //145
            price_completo: this.state.pricingAPI[configPrice1]['price']['usd']['price_view_base'], //495
            price_completo_regular: this.state.pricingAPI[configPrice1]['price']['usd']['price_view_base_regular'],
            price_payment: "1",
            salario_fullstack_jr: "$900 DÓLARES MENSUAL",
            salario_fullstack_md: "$1,300 DÓLARES MENSUAL",
            salario_fullstack_sr: "+ $2,000 DÓLARES MENSUAL",
        })


        try {
            if (this.state.pricingAPI[configPrice1].price[country]){
                this.setState({
                    isPricePais: true,
                    // price_cuota: this.state.pricingAPI[configPrice2].price[country].price_view_base,
                    price_completo: this.state.pricingAPI[configPrice1].price[country].price_view_base,
                    price_completo_regular: this.state.pricingAPI[configPrice1].price[country].price_view_base_regular,
                
                });
            }
        } catch (error) {
            console.log(error);
        }


        if (newCountry === 'PE') {
            this.setState({
                utc_offset: '-0500',
                salario_fullstack_jr: "S/ 3,500 MENSUAL",
                salario_fullstack_md: "S/ 5,000 MENSUAL",
                salario_fullstack_sr: "+ S/ 7,900 MENSUAL"
            });
        } else if (newCountry === 'MX') {
            this.setState({
                utc_dif: -1,
                utc_offset: '-0600',
                // price_cuota: "$7,375 MXN",
                // price_completo: "$14,750 MXN",
                // price_completo_regular: "$21,450 MXN",
                salario_fullstack_jr: "$17,500 MXN MENSUAL",
                salario_fullstack_md: "$25,500 MXN MENSUAL",
                salario_fullstack_sr: "+ $39,500 MXN MENSUAL"
                });
        } else if (newCountry === 'CR'){
            this.setState({utc_dif: -1, utc_offset: '-0600'});
        } else if (newCountry === 'CL'){
            this.setState({utc_dif: -1, utc_offset: '-0400'});
        } else if (newCountry === 'BO'){
            this.setState({
                utc_dif: -1,
                utc_offset: '-0400',
                });
        } else if (newCountry === 'CU'){
            this.setState({utc_dif: +1});
        } else if (newCountry === 'SV'){
            this.setState({utc_dif: -1});
        } else if (newCountry === 'GT'){
            this.setState({utc_dif: -1});
        } else if (newCountry === 'HN'){
            this.setState({utc_dif: -1, utc_offset: '-0600'});
        } else if (newCountry === 'NI'){
            this.setState({utc_dif: -1});
        } else if (newCountry === 'PY'){
            this.setState({utc_dif: +1, utc_offset: '-0300'});
        } else if (newCountry === 'PR'){
            this.setState({utc_dif: +1, utc_offset: '-0300'});
        } else if (newCountry === 'DO'){
            this.setState({utc_dif: +1});
        } else if (newCountry === 'UY'){
            this.setState({
                utc_dif: +2,
                utc_offset: '-0300'
            });
        } else if (newCountry === 'BR'){
            this.setState({
                utc_dif: +2,
                utc_offset: '-0300'
            });
        }

        if (newCountry === 'ES') {
            this.setState({
                utc_offset: '+0100',
            });
        }

    }

    
    handleChange(e){
        this.setState({ticket_type: e})

        if (e === 'free'){
            this.setState({colorTicket1: 'mediumseagreen'});
            this.setState({colorTicket2: 'black'});
            this.setState({colorTicket3: 'black'});
            
        }
        if (e === 'vip_live'){
            this.setState({colorTicket2: 'mediumseagreen'});
            this.setState({colorTicket1: 'black'});
            this.setState({colorTicket3: 'black'});
        }
        if (e === 'vip_plataforma'){
            this.setState({colorTicket3: 'mediumseagreen'});
            this.setState({colorTicket2: 'black'});
            this.setState({colorTicket1: 'black'});
        }
    }

    DownloadPdf(e) {
        // this.setState({isOpenTemario: !this.state.isOpenTemario});
        // const urlView = 'https://d352y0uj302yx3.cloudfront.net/web/viewer.html?file=https://media.dojopy.com/temario-pdf/AWS_Bootcamp_Cloud_EnVivo.pdf'
        // window.open(urlView, '_blank').focus();


        e.preventDefault();

        if(this.state.Phone === "" ){
            toast.warning('El campo Phone es requerido.');
            return;
        }

        // localStorage.setItem('email', this.state.Email);

        toast.success(`Enviamos el programa y temario completo por Email, gracias!`);
        this.openModalEmail();

        axios.post('https://cprij4toee.execute-api.us-east-1.amazonaws.com/dev',
        {lead: true, evento: false, bootcamp: 'CLOUD AWS', email: this.state.Email, name: this.state.Name, phone: this.state.Phone, country: this.state.countryCode})
        .then(res => {
            console.log(res);
            // window.location.href = `/inscripcion/fullstack/${this.state.countryCode}`;
            // toast.success(`Hola ${this.state.Name}!, tengamos una breve sesión informativa para completar tu inscripción`);
        })


    }

    openModal4(){
        this.setState({isOpenResume: !this.state.isOpenResume});
    }

    openModal () {
        this.setState({isOpen: !this.state.isOpen});
    }
    openModalEmail (){
        this.setState({isOpenTemario: !this.state.isOpenTemario});
    }

    openModal2 () {
        this.setState({isOpen2: !this.state.isOpen2});
    }
    openModalVideo(){
        this.setState({isOpenVideo: !this.state.isOpenVideo});
    }
    onSubmit2(e){
        e.preventDefault();
        const CUPON_VALID = 'marzo21';
        if (this.state.cupon.toLowerCase() === CUPON_VALID){
            this.DynamicCountryCupon(this.state.countryCode);
        } else {
            toast.warning("Ingresa un cupón correcto");
        }

    }


    onSubmit(e) {
        e.preventDefault();
        
        if(this.state.Phone === "" ){
            toast.warning('El campo Phone es requerido.');
            return;
        }
        // if(this.state.Name === "" ){
        //     toast.warning('El campo Nombre es requerido.');
        //     return;
        // }
        // if(this.state.Phone === "" ){
        //     toast.warning('El campo WhatsApp es requerido.');
        //     return;
        // }

        // localStorage.setItem('email', this.state.Email);
        localStorage.setItem('name', this.state.Name);
        localStorage.setItem('phone', this.state.Phone);

        
        this.setState({loadingEnroll: true});

        // if (this.state.Modalidad){
        //     localStorage.setItem('modalidad', this.state.Modalidad);
        // }
        // localStorage.setItem('phone', this.state.Phone);
        // localStorage.setItem('name', this.state.Name);
        // localStorage.setItem('lastname', this.state.LastName);
        // localStorage.setItem('country', this.state.countryCode);


        // toast.success(`${this.state.Name} enviamos tus datos al equipo de Dojopy, gracias!`);

        this.sleep(2000).then(r => {
            toast.success('¡Gracias por enviar tus datos! Pronto un asesor especializado estará en contacto contigo. ¡Muchas gracias!.');
            this.setState({isOpenTemario: false, isOpenModalInfo : false, selectFecha: false, loadingEnroll: false, isFormSend: true});
            window.fbq("track", "contact");
        })

        
        axios.post('https://cprij4toee.execute-api.us-east-1.amazonaws.com/dev',
        {evento: false, lead: true, bootcamp: 'cloud aws', email: this.state.Email, name: this.state.Name, country: this.state.countryCode,
        Token: "c3b4b89c",
        landing: 'home-aws',
        source: "home",
        phone: this.state.Phone
}
        ,)
        .then(res => {
            this.OpenWS();
        })

    }

    renderHorarioFinSemanaTarde(){
        if (this.state.utc_offset === "-0500") {
            return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">7:00 PM a 9:00 PM</p>
        } else if (this.state.utc_offset === "-0600") {
            return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">6:00 PM a 8:00 PM</p>
        } else if (this.state.utc_offset === "-0400"){
            return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">8:00 PM a 10:00 PM</p>
        }  else {
            return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">7:00 PM a 9:00 PM</p>
        }
    }

    renderHorario(){

        if (this.state.utc_offset === "-0500") {
            return  <p style={{fontSize: "20px", fontWeight: 500}} className="mb-1">10:00 AM a 12:00 M</p>
        } else if (this.state.utc_offset === "-0600") {
            return  <p style={{fontSize: "20px", fontWeight: 500}} className="mb-1">09:00 AM a 11:00 M</p>
        } else if (this.state.utc_offset === "-0400"){
            return  <p style={{fontSize: "20px", fontWeight: 500}} className="mb-1">11:00 AM a 13:00 PM</p>
        }  else {
            return  <p style={{fontSize: "20px", fontWeight: 500}} className="mb-1">10:00 AM a 12:00 M</p>
        }
  }


    OpenWS(){
        if (window.type_device){
            window.open("https://api.whatsapp.com/send?phone=51935489552&text=Hola,%20informes%20sobre%20el%20Bootcamp%20Cloud%20de%20AWS", '_blank').focus();
        } else {
            window.open("https://web.whatsapp.com/send?phone=51935489552&text=Hola,%20informes%20sobre%20el%20Bootcamp%20Cloud%20de%20AWS", '_blank').focus();
        }
    }

    OpenMeet(){
        window.open("https://dojopy.com/reunion#agendy", '_blank').focus();
    }


    sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

    onRenderOption ( option, index ) {
        return (
            <li>
            <ReactCountryFlag
                countryCode={option.key}
                svg
                style={{
                    width: '2.5em',
                    height: '2.5em',
                    padding: '1px'
                }}
                title="país"
            /> { option.key }
            </li>
                )
    }

    onRenderSelection( selected, settings, deselect ){
        return 	(<span style={{ padding: 2 }}>
		{ selected ?
            <span style={{"color": "black"}}>
                <ReactCountryFlag
                countryCode={selected.key}
                svg
                style={{
                    width: '2.5em',
                    height: '2.5em',
                    padding: '2px'
                }}
                title="país"
        /> {selected.key}
            </span>
            :
               <span style={{padding: 5}}></span>  
        }
	</span>)
    }


    renderFechaSelect(item, key){
        return (
            <div key={key} className="row row--35 align-items-center rn-address" data-aos="fade-up"  style={{boxShadow: "#c6c9d8 8px 6px 15px 1px"}}>
            <div className="col-12 mb-3">
                <h2 style={{marginBottom: "0px", fontSize: window.type_device && "39px" }}>
                    {item.fecha_inicio}
                </h2>
                <div style={{width: window.type_device ? "200px": "450px", height: "6px", backgroundColor: "#ffcb48", marginBottom: "20px", transform: "rotate(178deg)", marginTop: "-2px"}}></div>

                <div className="row">
                    <div className="col-md-2 col-12  mt-2">
                                <span style={{padding: "3px", "padding-top": "4px","padding-bottom": "4px", "background-color": "slateblue", "border-radius": "7px", "padding-left": "7px", "padding-right": "7px", "color": "white", "font-size": "18px"}}>
                                    <FiVideo/> Flexible
                                    </span>
                    </div>
                    <div className="col-md-3 col-12  mt-2">
                        <span style={{"padding-top": "4px","padding-bottom": "4px", "font-size": "18px", "color":"white", "background-color": "slateblue", "border-radius": "8px", "padding-left": "8px", "padding-right": "8px"}}>10 plazas limitadas </span>
                    </div>
                </div>

            </div>

            <div className="col-md-3 col-12" style={{height: "110px", borderRight: !window.type_device ? "1px solid #121212" : "none" }}>
                <div> <h5 className="mb-2" ><b> Duración</b></h5> </div>
                <div> <p style={{fontSize: "20px"}}> 16 horas
                (aprende a tu propio ritmo)
                <span style={{display: "inline-block"}}></span>
                </p> </div>
                <span className="bg-white m-1" style={{padding: "5px 7px", border: "1px solid mediumpurple", display: "inline-block", borderRadius: "10px", fontSize: "16px"}}>
                <b>{item.fecha_start_end}</b>
                </span>
            </div>

            <div className="col-md-3 col-12" style={{height: "110px", marginTop: window.type_device ? "25px": "none", borderRight: !window.type_device ? "1px solid #121212" : "none" }}>
                <div> <h5 className="mb-2"><b> Días</b></h5> </div>
                <div> <p style={{fontSize: "20px"}}> {item.dias}  </p> </div>
            </div>

            <div className="col-md-3 col-12" style={{height: "110px", marginTop: "none"}}>
                <div> <h5 className="mb-2"><b> Horario </b></h5> </div>
                
                <div className="row">
                    <div className="col-12">
                    {this.renderHorarioFinSemanaTarde()}
                    </div>

                </div>                               
            </div>

            <div className="col-md-3 col-12" style={{marginTop: "30px"}}>
                <div>
                <a href={"#inversion"}>
                <button
                style={{lineHeight: "20px", fontSize: "18px", marginBottom: "13px", padding: "15px 30px" }}
                className=" btn-efect">
                ELEGIR FECHA</button>
                </a>

                </div>
            </div>

        </div>
        )
      }


    renderFechas() {

        return (
            <div>
                {!this.state.select_mes && this.state.DataFechas.map((item, key) => (

                    this.state.select_turno ?
                     this.state.select_turno == item.turno && this.renderFechaSelect(item, key)
                    :
                    this.renderFechaSelect(item, key)

                ))}

                {this.state.select_mes && this.state.DataFechas.map((item, key) => (

                    // this.state.select_mes == item.mes && this.renderFechaSelect(item, key)
                    
                    this.state.select_turno ?
                    this.state.select_turno == item.turno && this.state.select_mes == item.mes && this.renderFechaSelect(item, key)
                   :
                    this.state.select_mes == item.mes && this.renderFechaSelect(item, key)


                ))}
          </div>

        )}



    renderMes(){
        return (
            <>
            <span style={{
                fontSize: "17px",
                fontWeight: "bold",
                position: "absolute",
                left: "15px",
                top: "-25px",
                zIndex: "1",
            }} >Mes:</span>
            <Selectrix
            height={250}
            placeHolderInside={true}
            placeholder={"Todos"}
            customScrollbar={true}
            searchable={false}
            materialize={false}
            defaultValue={this.state.select_mes}
            options={this.state.DataFechas.map((item) => ({key: item.mes, label: item.mes})) }
            onChange={value => this.setState({select_mes: value.key})}
        />
            </>
    
        )
    }
    
    renderTurno(){
        return (
            <>
            <span style={{
                fontSize: "17px",
                fontWeight: "bold",
                position: "absolute",
                left: "15px",
                top: "-25px",
                zIndex: "1",
            }} >Turno:</span>
            <Selectrix
            height={250}
            placeHolderInside={true}
            placeholder={"Todos"}
            customScrollbar={true}
            searchable={false}
            materialize={false}
            defaultValue={this.state.select_turno}
            options={Turnos}
            onChange={value => this.setState({select_turno: value.key})}
        />
            </>
    
        )
    }


renderPais(){
        return (
            <>
            <span style={{
                fontSize: "17px",
                fontWeight: "bold",
                position: "absolute",
                left: "15px",
                top: "-25px",
                zIndex: "1",
            }} > Zona horaria </span>
            <Selectrix
            height={300}
            onRenderOption={this.onRenderOption}
            onRenderSelection={this.onRenderSelection}
            placeHolderInside={true}
            placeholder={"País"}
            customScrollbar={true}
            searchable={false}
            materialize={false}
            defaultValue={this.state.countryCode}
            options={[
                {key: "PE", label: "Perú"},
                {key: "MX", label: "México"},
                {key: "BO", label: "Bolivia"},
                {key: "EC", label: "Ecuador"},
                {key: "CR", label: "Costa Rica"},
                {key: "PR", label: "Puerto Rico"},
                {key: "CL", label: "Chile"},
                {key: "ES", label: "España"},
                {key: "CO", label: "Colombia"},
                {key: "US", label: "Estados Unidos"},
                {key: "PY", label: "Paraguay"},
                {key: "UY", label: "Uruguay"},
                {key: "HN", label: "Honduras"},
                {key: "PA", label: "Panamá"},
                {key: "GT", label: "Guatemala"},
                {key: "CU", label: "Cuba"},
                {key: "SV", label: "El Salvador"},
                {key: "AR", label: "Argentina"},
                {key: "NI", label: "Nicaragua"},
                {key: "DO", label: "República Dominicana"},
                {key: "BR", label: "Brasil"}                                                
            ]}
            onChange={value => this.DynamicCountry(value.key)}
        />
            </>
        )
    }

renderPrice(price,  cuota){
    return (
        <>
        <div className="row" style={{placeContent: "center"}}>
            <div>
                <p style={{fontSize: "40px", "color": cuota ?  "black": "white", fontWeight: "bold", paddingTop: "9px", paddingBottom: "9px"}}>
                {this.state.isPricePais &&
                   <ReactCountryFlag
                        countryCode={this.state.countryCode}
                        svg
                        style={{
                            width: '1em',
                            height: '1em',
                            padding: '1px',
                            marginRight: '8px'
                        }}
                        title="país"
                    /> 
                   }
                {price}
                </p>
            </div>
        </div>
        </>
    )
    }

  
// renderPriceFooter(price){
//     return (
//         <>
//             <span style={{fontSize: "16px", "color": "white", fontWeight: 500, paddingTop: "5px", paddingBottom: "5px"}}>
//             Inversión: {price}
//             </span>
//             <ReactCountryFlag
//                     countryCode={this.state.countryCode}
//                     svg
//                     style={{
//                         width: '3.5em',
//                         height: '3.5em',
//                         padding: '9px'
//                     }}
//                     title="país"
//                 />
//         </>
//     )
//   }

  renderPriceFooter(price){
    return (
        <>
            <span style={{"color": "white", fontWeight: 500}}>
                <del style={{color: "white", fontSize: "16.5px", fontWeight: 600, marginRight: "12px" }}>
                    Antes: {this.state.price_contado_regular}
                </del>
            </span>
            
            <span style={{fontSize: "22px", "color": "white", fontWeight: 600, paddingTop: "5px", paddingBottom: "5px"}}>
            {price}
            </span>
            <ReactCountryFlag
                    countryCode={this.state.countryCode}
                    svg
                    style={{
                        width: '3.5em',
                        height: '3.5em',
                        padding: '9px'
                    }}
                    title="país"
                />
        </>
    )
  }



    render(){

        
        window.addEventListener('scroll', function() {
            var value = window.scrollY;
            if (value > 100) {
                document.querySelector('.header--fixed').classList.add('sticky')
            }else{
                document.querySelector('.header--fixed').classList.remove('sticky')
            }
        });

        var elements = document.querySelectorAll('.has-droupdown > a');
        for(var i in elements) {
            if(elements.hasOwnProperty(i)) {
                elements[i].onclick = function() {
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }
        

        const TypingAnimation =  React.memo(()=>{
            return !this.state.langUS ?
            <Typical
            className={'my-tipical'}
            steps={['Domina la nube de AWS']}
            loop={true}
            wrapper="h2"
                />:
                <Typical
                className={'my-tipical'}
                steps={['Create Full Stack Web Platforms', 500, 'Get your dream job.', 500, 'The best Full Stack Bootcamp in 5 months.']}
                loop={true}
                wrapper="h2"
                />
          },(props,prevProp)=> true ); // this line prevent re rendering

          const TypingAnimation2 =  ()=> {
            return !this.state.langUS ?
            <Typical
            steps={['Aprende online a tu propio ritmo ', 500 ,'con la guía de tu Mentor.', 1000]}
            loop={Infinity}
            wrapper="h2"/>
            :
            <Typical
            steps={['Learn 100% online live', 500 ,'with the guidance of your Mentor.', 1000]}
            loop={Infinity}
            wrapper="h2"
        />
          }; // this line prevent re rendering


        return (
            <React.Fragment>

                <Helmet>
                    <title>Bootcamp AWS | Cloud Amazon Web Services | 100% online en vivo | DojoFullStack</title>
                    <meta name="description" content="Bienvenido al bootcamp de Amazon Web Services (AWS) para desarrolladores web. Descubre cómo implementar tus aplicaciones web de forma robusta y escalable en la moderna infraestructura de AWS. Aprende a utilizar las últimas herramientas y tecnologías de AWS para crear aplicaciones web altamente efectivas y eficientes" />
                    <meta
                        property="og:description"
                        content="Bienvenido al bootcamp de Amazon Web Services (AWS) para desarrolladores web. Descubre cómo implementar tus aplicaciones web de forma robusta y escalable en la moderna infraestructura de AWS. Aprende a utilizar las últimas herramientas y tecnologías de AWS para crear aplicaciones web altamente efectivas y eficientes"
                    />
                   <meta
                    name="twitter:description"
                    content="Bienvenido al bootcamp de Amazon Web Services (AWS) para desarrolladores web. Descubre cómo implementar tus aplicaciones web de forma robusta y escalable en la moderna infraestructura de AWS. Aprende a utilizar las últimas herramientas y tecnologías de AWS para crear aplicaciones web altamente efectivas y eficientes"
                    />
                </Helmet>

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.webp" langUS={this.state.langUS} countryCode={this.state.countryCode} DynamicCountry={this.DynamicCountry} bootcampSlug={this.state.bootcampSlug} />
                

                <div className="slider-activation slider-creative-agency with-particles" id="home">
              
                { false &&
                <div className="frame-layout__particles">
                <Particles
                    init={this.customInit}
                    options={{
                        fullScreen: { enable: false },
                        fpsLimit: 120,
                        interactivity: {
                        events: {
                            onHover: {
                                enable: true,
                                mode: "repulse",
                            },
                            resize: true,
                        },
                        modes: {
                            push: {
                                quantity: 4,
                            },
                            repulse: {
                                distance: 200,
                                duration: 0.4,
                            },
                        },
                        },
                    particles: {
                        color: {
                            value: "#ffffff",
                        },
                        links: {
                            color: "#ffffff",
                            distance: 150,
                            enable: true,
                            opacity: 0.5,
                            width: 1,
                        },
                        collisions: {
                            enable: true,
                        },
                        fullScreen: { enable: false },
                        move: {
                            direction: "none",
                            enable: true,
                            outModes: {
                                default: "bounce",
                            },
                            random: false,
                            speed: 2,
                            straight: false,
                        },
                        number: {
                            density: {
                                enable: true,
                                area: 800,
                            },
                            value: 19,
                        },
                        opacity: {
                            value: 0.5,
                        },
                        shape: {
                            type: "circle",
                        },
                        size: {
                            value: { min: 1, max: 5 },
                        },
                    },
                }}

                />;
                
    </div>
    }

                    <div className="bg_image bg_image--27" >
                            
                                <div className="slide slide-style-2 slider-paralax d-Flex align-items-center justify-content-center" style={{paddingTop: "120px"}}>
                                    <div className="container">

                                        <div className="row">

                                            <div className="col-lg-12">

                                                <div className="inner text-left" style={{"text-align-last": "left"}}>


                                                {window.type_device ?
                                            <span style={{borderRadius: "10px", padding: "3px", color: "white" ,fontWeight:"bold", height: "30px", fontSize: "15px" ,"width": "143px","border": "1px solid white", "text-align-last": "center"}}>
                                            BOOTCAMP
                                            </span>:
                                            <span style={{borderRadius: "10px", padding: "3px", color: "white" ,fontWeight:"bold", height: "35px", fontSize: "17px" ,"width": "155px","border": "1px solid white", "text-align-last": "center"}}>
                                            BOOTCAMP
                                            </span>
                                        }

{this.state.fechaLimite &&
<div className="pricing-header pb-0 mb-1 mt-1">
                    <p className="my-0 py-0" style={{color: 'white', fontSize: '20px', textAlign: 'center'}}>Inicia en</p>
                <div className="mb-3">
                    <FlipCountdown
                        size={'small'}
                        yearTitle='Year'
                        dayTitle='Días'
                        hourTitle='Horas'
                        minuteTitle='Min'
                        secondTitle='Segs'
                        monthTitle='Mes'
                        hideYear
                        hideMonth
                        endAt={this.state.fechaLimite} // Date/Time
                    />
                </div>
                </div>}


                                                <h3 className="title" style={{"text-align": "left", marginBottom: "3px", color: "white"}}>DESARROLLADOR CLOUD</h3>
                                                <h1 className="title title-gradient" style={{"text-align": "left"}}>AMAZON WEB SERVICES</h1>

                                                <div className="row col-12 mb-3" style={{textAlign: "left", height: "60px"}}>
                                                    <TypingAnimation/>
                                                    </div>                                                
                                                 
                                                    
                                                    <p className="description" style={{"padding-top": "9px", fontSize: "18px"}}>


                                                    <div className="row">
                                                        <div style={{"text-align-last": "right"}} className="col-1"><FiCheck style={{"color": "yellow"}}/></div>
                                                        <div className="col-10" style={{paddingBottom: "9px", textAlign: "left" }}>
                                                            100% online
                                                            <span style={{display: 'inline-block', padding: "3px",marginLeft: "7px", "background-color": "slateblue", "border-radius": "7px", "padding-left": "7px", "padding-right": "7px", "color": "white", "font-size": "18px"}}>
                                                            <FiVideo/>  {!this.state.langUS ? "Flexible": "Live"}
                                                            </span>
                                                         </div>
                                                    </div>


                                                    <div className="row">
                                                        <div style={{"text-align-last": "right"}} className="col-1"><FiCheck style={{"color": "yellow"}}/></div>
                                                        <div className="col-10" style={{paddingBottom: "9px", textAlign: "left" }}>
                                                            Completa el bootcamp a tu propio ritmo.
                                                         </div>
                                                    </div>


                                                    <div className="row">
                                                        <div style={{"text-align-last": "right"}} className="col-1"><FiCheck style={{"color": "yellow"}}/></div>
                                                            <div className="col-10" style={{paddingBottom: "9px", textAlign: "left" }}>
                                                            Retroalimentación y <br/> Tutorías 1 a 1.
                                                            </div>
                                                    </div>

                                                        </p>

                                                    <div className="slide-btn">
                                                   

                                                    <a href="#informes">
                                        <button  className="btn-efect" type="submit" name="submit" id="mc-embedded-subscribe-" style={{maxWidth: "95%", fontSize: "23px", marginTop: '5px', fontWeight: "bold"}}>
                                {this.state.loadingEnroll  &&
                                                <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                                style={{fontSize: "20px", marginRight: "5px"}}
                                                />}
                                Solicitar información
                                </button>
                                        </a>
                                                    
                                                        </div>
                                                    <span style={{"font-size": "10px", "paddingTop": "7px"}}> Calidad 100% asegurada </span>
                                              

                                                

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            
                        </div>
                {/* End Slider Area   */}
                </div>




                {true &&
<div className="py-3" style={{background: 'linear-gradient(135deg, #C56CD6 0%, #3425AF 100%)'}} id="informes">

            <div className="contact-form--1">
                        <div className="container" style={{maxWidth: "450px"}}>
                            <div className="row row--35 align-items-center justify-content-center">
                                <div>

                                <div style={{textAlign: "-webkit-center"}} className="form-wrapper">
                                {/* <div className="thumbnail" style={{paddingBottom: "8px", maxWidth: window.type_device ? "310px": "370px" }}>
                                <img style={{boxShadow: "#424241 6px 5px 15px 0px"}} className="image_temario" src="/assets/images/dojopy/beca-parcial-flex.webp" alt="temario bootcamp"/>
                                </div> */}



                                <p style={{
                                    marginTop: '15px',
                                    color: "white",
                                    fontSize: '33px',
                                    fontWeight: 'bold',
                                    fontFamily: "'Poppins',sans-serif",
                                }} className="subtitle-info">
                                   Recibir más Información
                                </p>

                                <p className="my-1 text-white">
                                    Obtén información detallada sobre el programa online, beneficios exclusivos y testimonios de nuestros alumnos.
                                </p>

                     <form onSubmit={this.onSubmit}>
                        <div className="row p-1">
                        <div className="col-12 text-center p-1" >

                        <label class="col-12 text-left font-weight-bold px-4 text-white" htmlFor="nameDojo">
                        <span style={{fontSize: "17px", color: "white"}}>Nombre:</span>
                            <input
                            id='nameDojo'
                            style={{marginBottom: "5px", backgroundColor: 'white'}}
                            type="text"
                            name="name"
                            value={this.state.Name}
                            onChange={(e) => this.setState({ Name: e.target.value})}
                            placeholder= {"👋 Hola! ¿Cuál es tu nombre?"}
                            />
                        </label>

                        {/* <label class="col-12 text-left font-weight-bold px-4 text-white" htmlFor="emailDojo">
                            <span style={{fontSize: "17px", color: "white"}}>Email:</span>
                            <input
                            style={{marginBottom: "5px", backgroundColor: 'white'}}
                            type="email"
                            name="email"
                            id="emailDojo"
                            value={this.state.Email}
                            onChange={(e) => this.setState({ Email: e.target.value})}
                            placeholder="📧 ¿Cuál es tu correo electrónico?"
                            />
                        </label> */}


     <div className="text-left" >
    <b style={{fontSize: "17px", marginLeft: "20px", color: "white"}}>
        WhatsApp:
    </b>
    
</div> 

 <div className="d-flex justify-content-center mx-3">

<div className="mx-2 mt-2">
    <Selectrix
    className="pais-inscription"
    height={300}
    onRenderOption={this.onRenderOption}
    onRenderSelection={this.onRenderSelection}
    placeHolderInside={true}
    placeholder={"País"}
    customScrollbar={true}
    searchable={false}
    materialize={false}
    defaultValue={this.state.countryCode}
    options={KeyCountry}
    onChange={(value) => {
        if (this.state.Email){
            localStorage.setItem('email', this.state.Email);
        }
        if (this.state.Name){
            localStorage.setItem('name', this.state.Name);
        }
        if (this.state.Phone){
            localStorage.setItem('phone', this.state.Phone);
        }
    } }
    />

</div>

<div style={{width: "80%"}}>
<label htmlFor="Phone">
    
    <input
    style={{background: "white"}}
    type="text"
    name="phone"
    id="Phone"
    value={this.state.Phone}
    onChange={(e) => {
        this.setState({ Phone: e.target.value });
    }}
    placeholder="N.º de WhatsApp"
    />
 </label>
</div>

</div>




                                <button className="btn-efect" type="submit" name="submit" id="mc-embedded-subscribe-" style={{maxWidth: "95%", fontSize: "23px", marginTop: '5px', fontWeight: "bold"}}>
                                {this.state.loadingEnroll  &&
                                                <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                                style={{fontSize: "20px", marginRight: "5px"}}
                                                />}
                                Recibir más información
                                </button>

                                <div className="col-12 mt-2 text-center" style={{color: "white", fontSize: "13px", textAlign: "center"}}>
                    <label htmlFor="checkNotify" style={{float: "left", color: "black"}}>
                        <input
                            style={{width: 16, float: "left", marginTop: "-10px", marginRight: "9px", marginBottom: "1px"}}
                            defaultChecked={true}
                            type="checkbox"
                            name="checkNotify"
                            id="checkNotify"
                        />
                        <span className="text-white">{!this.state.langUS ? "Protección de datos y privacidad.": "Data protection and privacy."} </span>
                    </label>
                    </div>

                        </div>
                        </div>

                        </form>

                   


                        </div>
                        </div>
                        </div>
                        </div>
                        </div>
                        
    
</div>
}





{/* Start About Area */}
<div data-aos="fade-up" className="about-area ptb--80 bg_color--5" id="about_dojopy">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                            
                                <div className="col-12">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title">Bootcamp Cloud AWS
                                            <span style={{padding: "3px",marginLeft: "7px", "background-color": "slateblue", "border-radius": "7px", "padding-left": "7px", "padding-right": "7px", "color": "white", "font-size": "18px"}}>
                                                            <FiVideo/> Flexible
                                                            </span>
                                            </h2>
                                            <p>
                                            Bienvenido al bootcamp de Amazon Web Services (AWS) para desarrolladores web,
                                            Implementa tus aplicaciones web de forma robusta y escalable en la moderna infraestructura de AWS.
                                            </p>


                                        </div>
                                    <div className="row mt--30 rn-address" style={{boxShadow: "#c6c9d8 8px 6px 15px 1px"}}>
                                            <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h3 className="title"><FiClock/> Duración</h3>
                                                    <p style={{fontSize: "20px"}}>
                                                    <span style={{"color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "21px", marginRight: "7px", color: "slateblue"}} /> </span>
                                                    16 horas
                (aprende a tu propio ritmo)
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h3 className="title"><FiUser/> Modalidad</h3>
                                                    <p style={{fontSize: "20px"}}>
                                                    <span style={{"color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "21px", marginRight: "7px", color: "slateblue"}} /> </span>
                                                        100% online
                                                        <span style={{padding: "3px",marginLeft: "7px", "background-color": "slateblue", "border-radius": "7px", "padding-left": "7px", "padding-right": "7px", "color": "white", "font-size": "18px"}}>
                                                            <FiVideo/> Flexible
                                                            </span>
                                                                        <br/>
                                                                        <div className="pt-2">
                                                                        <span style={{"color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "21px", marginRight: "7px", color: "slateblue"}} /> </span> 
                                                                        Retroalimentación y tutorías 1 a 1.<br/>

                                                                        </div>

                                                    </p>
                                                </div>
                                            </div>


                                     

                                            <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h3 className="title">Requisitos</h3>
                                                    <p>
                                                    <span style={{"color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "21px", marginRight: "7px", color: "slateblue"}} /> </span>
                                                    <a style={{"cursor": "pointer", "text-decoration": "underline"}} onClick={this.openModal2}>Ver</a>
                                                    
                                                    </p><br/>
                                                </div>
                                            </div>


                                            <div className="col-lg-4 col-md-6 col-sm-12 col-12" style={{marginTop: window.type_device? "10px": "1px"}}>
                                                <div className="about-us-list">
                                                    <h3 className="title"><GiPlatform/>  {!this.state.langUS ? "Plataforma": "Modality"} </h3>
                                                    <p style={{fontSize: "20px"}}>
                                                    <span style={{"color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "21px", marginRight: "7px", color: "slateblue"}} /> </span>

                                                    Acceso de por vida.
                                                 
                                                     <br/>
                                                        <div className="pt-2">
                                                        <span style={{"color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "21px", marginRight: "7px", color: "slateblue"}} /> </span> 
                                                         {!this.state.langUS ? "Plataforma interactiva." : "Receive Mentoring 1 to 1"}<br/>
                                                        </div>
                                                    </p>
                                                </div>
                                            </div>


                                            <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h3 className="title">Temario</h3>
                                                    <p>
                                                    <a className="rn-btn" target="_blank" href="https://plataforma-dojopy-media.s3.amazonaws.com/temario-pdf/AWS_Bootcamp_Cloud_flex.pdf" style={{fontSize: "20px", padding: "10px"}} >
                                                <FiDownload style={{fontSize: "18px", margin: "3px"}}/>
                                                    Ver Temario
                                                </a>
                                                    </p><br/>
                                                </div>
                                            </div>


                                            <div className="col-12 col-md-9 col-lg-9">
                                                <div className="about-us-list" style={{maxWidth: "450px"}}>
                                                <h3 className="title"><FaGraduationCap/> Certificado</h3>
                                                    <img onContextMenu={(e) => e.preventDefault()} src="/assets/images/dojopy/certificado_bootcamp_aws.webp"></img>
                                                </div>
                                            </div>

                                    </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area */}



{/* {
    window.type_device ?
        <div>
            <Card className="mb-5">
                <div className="card-img-top sh-50 cover">
                    <Player
                    playsInline
                    poster="https://media-dojopy.s3.amazonaws.com/amazon_ecommerce.webp"
                    src="https://media-dojopy.s3.amazonaws.com/project_amazon.mp4"
                    />
                </div>
            </Card>    
        </div> :
    <div className="container">
        <Card className="mb-5">
            <div className="card-img-top sh-50 cover">
                <Player
                playsInline
                poster="https://media-dojopy.s3.amazonaws.com/amazon_ecommerce.webp"
                src="https://media-dojopy.s3.amazonaws.com/project_amazon.mp4"
                />
            </div>
        </Card>    
    </div>

} */}





    {/* Start About Area */}
    <div data-aos="fade-right" className="about-area ptb--120 bg_color--5" id="benefits" style={{marginTop: "-100px"}}>
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                              
                                <div className="col-12 col-md-9">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title">
                                            Un vistazo a tu futuro perfil
                                            </h2>
                                            <h3 className="title mt-4">
                                            Puesto prestigioso
                                            </h3>
                                        </div>
                                        <div>
                                        <span className="bg-white m-1" style={{padding: "5px 7px", border: "1px solid mediumpurple", display: "inline-block", borderRadius: "10px", fontSize: "18px"}}>Desarrollador Cloud AWS</span>
                                        <span className="bg-white m-1" style={{padding: "5px 7px", border: "1px solid mediumpurple", display: "inline-block", borderRadius: "10px", fontSize: "18px"}}>Arquitecto Cloud</span>
                                        <span className="bg-white m-1" style={{padding: "5px 7px", border: "1px solid mediumpurple", display: "inline-block", borderRadius: "10px", fontSize: "18px"}}>AWS Engineer</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12">
                                            <h3 className="title mt-4">
                                            Actividades interesantes
                                            </h3>
                                            <div>
                                                <p style={{fontSize: "17px", "margin-bottom": "10px"}}>
                                                    <span style={{"color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "21px", marginRight: "7px", color: "slateblue"}} /> </span>
                                                    Diseñar aplicaciones nativas de la nube con alta escalabilidad, disponibilidad y confiabilidad.
                                                </p>
                                                <p style={{fontSize: "17px", "margin-bottom": "10px"}}>
                                                    <span style={{"color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "21px", marginRight: "7px", color: "slateblue"}} /> </span>
                                                    Implementar aplicaciones.
                                                </p>
                                                <p style={{fontSize: "17px", "margin-bottom": "10px"}}>
                                                    <span style={{"color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "21px", marginRight: "7px", color: "slateblue"}} /> </span>
                                                    Integrar servicios de AWS.
                                                </p>
                                                <p style={{fontSize: "17px", "margin-bottom": "10px"}}>
                                                    <span style={{"color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "21px", marginRight: "7px", color: "slateblue"}} /> </span>
                                                    Compilar y probar aplicaciones.
                                                </p>

                                                <p style={{fontSize: "17px", "margin-bottom": "10px"}}>
                                                    <span style={{"color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "21px", marginRight: "7px", color: "slateblue"}} /> </span>
                                                    Administrar la supervisión del rendimiento de la aplicación.
                                                </p>
                                                
                                            </div>
                                    </div>

                                    {/* <div className="col-12 col-md-9">
                                            <h3 className="title mt-4">
                                            Habilidades claves para el mercado laboral
                                            </h3>
                                            <div>
                                            <TouchCarousel
                                                component={Container}
                                                cardSize={cardSize}
                                                cardCount={data.length}
                                                cardPadCount={cardPadCount}
                                                loop={true}
                                                autoplay={2e3}
                                                renderCard={this.renderCard}
                                                />
                                            </div>
                                    </div> */}

                            
                                    <div className="col-12 col-md-9">
                                            <h3 className="title mt-4">
                                            Salario alto y perspectivas seguras
                                            </h3>
                                            <p style={{fontSize: "13px"}}>Estos salarios mensuales promedio están tomados de Computrabajo, el portal de empleo líder en Latinoamérica. Pero tú puedes ser mejor que el promedio.</p>
                                            <div style={{boxShadow: "3px 2px 6px 0px #808080b0"}} className="p-3 m-2 bg-white col-10 col-md-6">
                                                <h3 style={{fontWeight: "500"}}>$2,900 DOLARES MENSUAL</h3>
                                                <p>Desarrollador AWS Jr. <span className="text-primary d-inline-block"> después del bootcamp</span> </p>
                                            </div>
                                            <div style={{boxShadow: "3px 2px 6px 0px #808080b0"}} className="p-3 m-2 bg-white col-11 col-md-8">
                                                <h3 style={{fontWeight: "500"}}>+ $4,900 DOLARES MENSUAL</h3>
                                                <p>Desarrollador AWS Experimentado <span className="text-primary d-inline-block">en 1 o 2 años</span> </p>
                                            </div>
                                         
                                    </div>

                            </div>
                </div>
            </div>
        </div>
        {/* End About Area */}




 {/* Start About Area */}
 <div data-aos="fade-right" className="about-area ptb--120 bg_color--5" id="benefits" style={{marginTop: "-100px"}}>
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-12">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                        <BsLightningFill style={{fontSize: "40px", color: "lightgreen"}}/>
                                            <h2 className="title">
                                                Beneficios de aprender AWS
                                            </h2>
                                          
                                        </div>
                                    <div className="row mt--30">
                                          
                                    <div className="col-12">
                                                <div className="">
                                                    <h4 style={{fontWeight: 400}}>
                                                    <FiCheckCircle style={{fontSize: "21px", marginRight: "7px", color: "slateblue"}} />
                                                    Es el proveedor de servicios en la nube que más crece y respaldada por grandes empresas.
                                                    </h4>
                                                </div>
                                            </div>

                                        
                                            <div className="col-12">
                                                <div className="">
                                                    <h4 style={{fontWeight: 400}}>
                                                    <FiCheckCircle style={{fontSize: "21px", marginRight: "7px", color: "slateblue"}} />
                                                    Cada vez mas organizaciones están moviendo sus sistemas de un ambiente local a un ambiente en AWS (en la nube).
                                                    </h4>
                                                </div>
                                            </div>

                                            <div className="col-12">
                                                <div className="">
                                                    <h4 style={{fontWeight: 400}}>
                                                    <FiCheckCircle style={{fontSize: "21px", marginRight: "7px", color: "slateblue"}} />
                                                    No es necesario realizar un compromiso con contratos por largos tiempos y elevados costos.
                                                    </h4>
                                                </div>
                                            </div>
                                         
                                            <div className="col-12">
                                                <div className="">
                                                    <h4 style={{fontWeight: 400}}>
                                                    <FiCheckCircle style={{fontSize: "21px", marginRight: "7px", color: "slateblue"}} />
                                                    La certificación de AWS es una de las mas importantes y con mayor valor entre las certificaciones del área tecnologias de información (IT).
                                                    </h4>
                                                </div>
                                            </div>

                                            <div className="col-12">
                                                <div className="">
                                                    <h4 style={{fontWeight: 400}}>
                                                    <FiCheckCircle style={{fontSize: "21px", marginRight: "7px", color: "slateblue"}} />
                                                    Es posible presentar el examen de aws en español que permite al usuario obtener la certificacion de AWS. El examen sobre AWS en español dura aproximadamente 90 m, en los cuales el usuario deberá contestar una serie de preguntas acerca de dichos servicios.
                                                    </h4>
                                                </div>
                                            </div>

                                     
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area */}




           
   {/* Start About Area */}
   <div data-aos="fade-right" className="about-area ptb--50 bg_color--5"  style={{marginTop: "1px", color: "black"}}>
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                            <div className="text-center col-12">
                            <span style={{padding: "3px", marginLeft: "7px", "background-color": "slateblue", "border-radius": "7px", "padding-left": "7px", "padding-right": "7px", "color": "white", "font-size": "18px"}}>
                                                            <FiVideo/> Flexible
                                                            </span>
                                <h2 className="title text-black" style={{padding: "5px"}}>
                                    ¿Cómo funciona el Bootcamp?
                                    </h2>
                                    
                            </div>
                               <div className="p-3">
                                   <p>
                                   Un bootcamp flexible funciona proporcionando a los estudiantes acceso a los materiales de estudio y las tareas en línea, lo que les permite avanzar a su propio ritmo y organizar su horario de estudio de acuerdo a sus necesidades. Los estudiantes pueden acceder a los recursos en cualquier momento y lugar, lo que les brinda la flexibilidad de aprender cuando les sea conveniente. Además, suelen contar con sesiones de apoyo con instructores o tutores para resolver dudas y recibir retroalimentación. Este enfoque permite a los estudiantes adaptar su aprendizaje a sus horarios y responsabilidades, lo que les permite alcanzar sus objetivos educativos de manera más flexible y personalizada.

                                    </p>
                                    

                                  
                                    {/* <br/>
                                    <div className="text-center">
                                    <button onClick={() => this.openModalSuscribe()} style={{fontSize: "20px", textAlignLast: "center", "font-weight": "600"}} className="rn-button-style--2 btn-primary-color-4 mt-2">
                                            <FaEye style={{marginRight: "7px", fontSize: "23px"}} />
                                            Ver evento informativo</button>
                                    </div>  */}
                               </div>

                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area */}






{/* Start Team Area  */}
<div data-aos="fade-up" className="rn-team-area ptb--120 bg_color--1" id="modules">
                    <div className="container" style={{"maxWidth": "900px"}}>
                        <div className="row">
                            <div className="col-12">
                                <div className="text-center mb--30">
                                    <h3 className="title">El Bootcamp está formado por los siguientes módulos</h3>
                                </div>
                                <div>
                            <AccordionWrapper>
                                <AccordionItem index={0} title={"Introducción a AWS y Cloud Services"} description={InfoAcordeon.intro}></AccordionItem>
                                <AccordionItem index={1} title={"AWS IAM"} description={InfoAcordeon.iam}></AccordionItem>
                                <AccordionItem index={2} title={"AWS S3"} description={InfoAcordeon.s3}></AccordionItem>
                                <AccordionItem index={3} title={"CloudFront"} description={InfoAcordeon.cloudfront}></AccordionItem>
                                <AccordionItem index={4} title={"Amazon Elastic Compute Cloud (EC2)"} description={InfoAcordeon.ec2}></AccordionItem>
                                <AccordionItem index={5} title={"AWS Certificate Manager (SSL)"} description={"Descargar temario"}></AccordionItem>
                                <AccordionItem index={6} title={"Bases de datos RDS"} description={InfoAcordeon.rds}></AccordionItem>
                                <AccordionItem index={8} title={"Serverless con Lambda"} description={InfoAcordeon.serverless}></AccordionItem>
                                <AccordionItem index={9} title={"AWS ApiGateway"} description={"Descargar temario"}></AccordionItem>
                                <AccordionItem index={10} title={"AWS Route53"} description={InfoAcordeon.route53}></AccordionItem>
                                <AccordionItem index={11} title={"AWS Amplify"} description={InfoAcordeon.amplify}></AccordionItem>
                                <AccordionItem index={13} title={"AWS SES Email Services"} description={InfoAcordeon.ses}></AccordionItem>
                                <AccordionItem index={16} title={"Desplegar plataforme E-commerce en AWS"} description={InfoAcordeon.project}></AccordionItem>
                            </AccordionWrapper>
                                </div>
                            </div>
                        </div>
                    </div>
                    

        </div>
            {/* End Team Area  */}




  {/* Start Team Area  */}
  <div data-aos="fade-up" className="rn-team-area ptb--20 bg_color--1" id="roadmap" style={{marginBottom: "-5px" }}>
                    <div className="container" style={{"maxWidth": "900px"}}>
                        <div className="row">
                            <div className="col-12">
                                <div className="text-center mb--30">
                                    <h2 className="title">RoadMap</h2>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                {/* End Team Area  */}




<VerticalTimeline
    lineColor="slateblue"
>

<VerticalTimelineElement
    className="vertical-timeline-element--work"
    contentStyle={{ background: 'darkgreen', color: 'white' }}
    contentArrowStyle={{ borderRight: '7px solid  darkgreen' }}
    iconStyle={{ background: 'darkgreen', color: 'white' }}
    icon={<GoRocket style={{fontSize: "25px"}}/>}
  >
    <h3 className="vertical-timeline-element-title">Acceso Plataforma</h3>
    <p>
    Como miembro de Dojopy, tendrás acceso inmediato a la
    Plataforma innovadora para prepararse y practicar antes de las sesiones en vivo del Bootcamp.
    La plataforma está impulsada por Inteligencia Artificial y ofrece lecciones y retroalimentación personalizadas
    según el progreso del estudiante.
    También hay acceso a múltiples módulos, proyectos y soluciones compartidas de la comunidad, así como una comunidad propia de Dojopy.
    </p>
  </VerticalTimelineElement>


<VerticalTimelineElement
    className="vertical-timeline-element--work"
    contentStyle={{ background: 'darkgreen', color: 'white' }}
    contentArrowStyle={{ borderRight: '7px solid  darkgreen' }}
    iconStyle={{ background: 'darkgreen', color: 'white' }}
    icon={<GoRocket style={{fontSize: "25px"}}/>}
  >
    <h3 className="vertical-timeline-element-title">Desarrollo Cloud Native</h3>
    <p>
    En la primera semana se abordan conceptos fundamentales para el diseño y despliegue de aplicaciones Cloud Native trabajando a fondo con los SDKs de AWS.
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    contentStyle={{ background: 'midnightblue', color: 'white' }}
    contentArrowStyle={{ borderRight: '7px solid  midnightblue' }}
    iconStyle={{ background: 'midnightblue', color: 'white' }}
    icon={<FaRunning />}
  >
    <h3 className="vertical-timeline-element-title">Metodologías modernas de implementación Cloud AWS</h3>
    <p>
    Se integran múltiples servicios y metodologías que permiten mejorar la calidad de la aplicación, ser más productivo al trabajar con tecnologías Cloud.	
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    contentStyle={{ background: 'midnightblue', color: 'white' }}
    contentArrowStyle={{ borderRight: '7px solid  midnightblue' }}
    className="vertical-timeline-element--work"
    iconStyle={{ background: 'midnightblue', color: '#fff' }}
    icon={<FaRunning />}
  >
    <h3 className="vertical-timeline-element-title">Implementación de aplicaciones Frontend</h3>
    <p>
    Aquí implementamos servicios como S3, CloudFront y Amplify claves para escalar aplicaciones Frontend.
    </p>
  </VerticalTimelineElement>

  <VerticalTimelineElement
    contentStyle={{ background: 'midnightblue', color: 'white' }}
    contentArrowStyle={{ borderRight: '7px solid  midnightblue' }}  
    className="vertical-timeline-element--work"
    iconStyle={{ background: 'midnightblue', color: '#fff' }}
    icon={<FaRunning />}
  >
    <h3 className="vertical-timeline-element-title">Implementación de aplicaciones Backend</h3>
    <p>
    Implementamos servicios como Ec2 (Elastic cloud compute), Amazon Elastic Container, claves para escalar aplicaciones Backend.
    </p>
  </VerticalTimelineElement>

  <VerticalTimelineElement
    contentStyle={{ background: 'midnightblue', color: 'white' }}
    contentArrowStyle={{ borderRight: '7px solid  midnightblue' }}  
    className="vertical-timeline-element--work"
    iconStyle={{ background: 'midnightblue', color: '#fff' }}
    icon={<FaRunning />}
  >
    <h3 className="vertical-timeline-element-title">Dominios y certificados AMAZON SSL</h3>
    <p>
    Implementamos servicios como Route 53 y AWS Certificate Manager (SSL) de forma segura.
      </p>
  </VerticalTimelineElement>

  <VerticalTimelineElement
    contentStyle={{ background: 'midnightblue', color: 'white' }}
    contentArrowStyle={{ borderRight: '7px solid  midnightblue' }}
    className="vertical-timeline-element--work"
    iconStyle={{ background: 'midnightblue', color: '#fff' }}
    icon={<FaRunning />}
  >
    <h3 className="vertical-timeline-element-title">Bases de Datos</h3>
    <p>
    En este módulo, implementamos servicios como RDS (Base de datos relacional)
    </p>
  </VerticalTimelineElement>



  <VerticalTimelineElement
    contentStyle={{ background: 'purple', color: 'white' }}
    contentArrowStyle={{ borderRight: '7px solid  purple' }}
    className="vertical-timeline-element--education"
    iconStyle={{ background: 'purple', color: '#fff' }}
    icon={<FaRunning />}
  >
    <h3 className="vertical-timeline-element-title">Tecnología Serverless de AWS</h3>
    <p>
    AWS ofrece tecnologías para ejecutar código, administrar datos e integrar aplicaciones, todo sin administrar servidores,
    Trabajarás de forma intensiva con tecnologías serverless que permitan liberar al desarrollador de administrar cualquier tipo de infraestructura de cómputo.
    </p>
  </VerticalTimelineElement>

  {/* <VerticalTimelineElement
    contentStyle={{ background: 'slateblue', color: 'white' }}
    contentArrowStyle={{ borderRight: '7px solid  slateblue' }}
    className="vertical-timeline-element--education"
    iconStyle={{ background: 'slateblue', color: '#fff' }}
    icon={<FaRunning />}
  >
    <h3 className="vertical-timeline-element-title">Redes y conectividad</h3>
    <p>
       Aquí diseñamos redes privadas en la infraestructura de AWS y maximizamos la seguridad de las aplicaciones.
    </p>
  </VerticalTimelineElement> */}

  <VerticalTimelineElement
    contentStyle={{ background: 'slateblue', color: 'white' }}
    contentArrowStyle={{ borderRight: '7px solid  slateblue' }}
    className="vertical-timeline-element--education"
    iconStyle={{ background: 'slateblue', color: '#fff' }}
    icon={<FaRunning />}
  >
    <h3 className="vertical-timeline-element-title">Escalamiento y monitoreo automáticos</h3>
    <p>
    Escala tus aplicaciones de forma automatizada en AWS.
    </p>
  </VerticalTimelineElement>

  <VerticalTimelineElement
    contentStyle={{ background: 'mediumseagreen', color: 'white' }}
    contentArrowStyle={{ borderRight: '7px solid  mediumseagreen' }}
    className="vertical-timeline-element--education"
    iconStyle={{ background: 'mediumseagreen', color: '#fff' }}
    icon={<FaRunning />}
  >
    <h3 className="vertical-timeline-element-title">Proyecto Final</h3>
        <p>
        Finalmente, para consolidar los conocimientos adquiridos en este Bootcamp, desplegaremos una plataforma
        de comercio electrónico completamente en la nube de AWS (Frontend, Backend y bases de datos).   
      </p>
  </VerticalTimelineElement>




  <VerticalTimelineElement
    contentStyle={{ background: 'goldenrod', color: 'white' }}
    contentArrowStyle={{ borderRight: '7px solid  goldenrod' }}
    iconStyle={{ background: 'goldenrod', color: '#fff' }}
    icon={<FaGraduationCap />}
  >
    <h3 className="vertical-timeline-element-title">Graduación BootCamp 2024</h3>
    <p>
        Soy AWS Cloud Developer! 😎
    </p>

  </VerticalTimelineElement>



</VerticalTimeline>




   
                {/* Start Brand Area */}
                <div className="rn-brand-area bg_color--1 ptb--80">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <BrandTwo />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area */}









                
                <div>
            </div>







            <div className="rn-pricing-table-area ptb--40 bg_color--5" data-aos="fade-right" id="inversion">

<div className="container px-1" >
    <div className="row" style={{justifyContent: "center", textAlign: "-webkit-center"}}>


<div className="col-12 mt-1" style={{paddingBottom: "13px", paddingRight: "15px", paddingLeft: "15px", maxWidth: "470px", marginRight: window.type_device ? "auto": "1px" }} >

<div className="rn-pricing" style={{boxShadow: "rgba(140, 82, 255, 0.9) 4px 4px 15px 3px", border: "none", background: "linear-gradient(135deg, #C56CD6 0%,#3425AF 100%)", borderRadius: "20px"}} >
<div className="pricing-table-inner">

<div className="pricing-header pb-0 mb-2 mt-1">

<p className="my-0 py-0" style={{color: 'white', fontSize: '23px', textAlign: 'center'}}>Inicia en</p>
<div className="mb-3">
<FlipCountdown
size={'small'}
yearTitle='Year'
dayTitle='Días'
hourTitle='Horas'
minuteTitle='Min'
secondTitle='Segs'
monthTitle='Mes'
hideYear
hideMonth
endAt={this.state.fechaLimite} // Date/Time
/>
</div>


<h3 className="text-white p-0 m-0">Bootcamp</h3>
<h3 className="text-white">Cloud AWS</h3>


<span style={{padding: "6px 10px", margin: "5px", "background-color": "slateblue", "border-radius": "7px", "color": "white", "font-size": "20px", fontWeight: "bold"}}>
Aprende a tu propio ritmo
</span>


<h3 className="text-white mt-1 mb-0">Costo Total</h3>

<span style={{color: "white", display: "inline-block"}}>
<del style={{color: "lightgray", fontSize: "23px"}}>
&nbsp; {this.state.price_completo_regular} &nbsp;
</del>
</span>


<h3 className="pricing" style={{marginBottom: "5px", marginTop: '1px'}} >
{this.renderPrice(this.state.price_completo)}
</h3>

{/* {this.state.countryCode == "MX" &&  <span style={{fontSize: "20px", color: "white", marginLeft: "3px"}}>  (Conversión) <br/> {this.state.priceConversion?.mx} </span>}
{this.state.countryCode == "BO" &&  <span style={{fontSize: "20px", color: "white", marginLeft: "3px"}}>  (Conversión) <br/> {this.state.priceConversion?.bo}</span>} */}



<div className="slide-btn" style={{marginTop: "15px", textAlign: "center"}}>
<a href={`/inscripcion/${this.state.bootcampSlug}/${this.state.countryCode}`}>
<button
onClick={this.TrackEventConversion}
id="enroll_bootcamp_2"
style={{lineHeight: "28px", fontSize: "20px", marginBottom: "13px", padding: "15px 30px" }}
className=" btn-efect">
<GoRocket style={{fontSize: "20px", marginRight: "10px"}}/>
EMPEZAR INSCRIPCIÓN
</button>
</a>
</div>


<div className="col-12 text-center mt-2">
<button
onClick={() => this.setState({isOpenResume: !this.state.isOpenResume})}
name="submit"
id="mc-embedded-subscribe-"
style={{color: "white", marginTop: "5px", marginBottom: "5px", fontSize: "22px",
"border-radius": "20px",
"border": "2px solid white",
"padding": "9px 20px"
}}>
Ver detalles
<AiOutlineRight style={{padding: "5px", fontSize: "30px"}}/>
</button>
</div>


</div>

</div>
</div>
</div>

{/* End PRicing Table Area  */}

</div>
</div>
</div>


            
                <Modal show={this.state.isOpen2} size="md">
                    <Modal.Body>
                    <div className="about-inner inner">
                        <div className="section-title">
                        <h4 style={{marginTop: "5px", marginBottom: "7px"}} className="title">Requisitos</h4>
                            <p>
                            No te preocupes. Nuestros programas son aptos para principiantes y no requieren ningún conocimiento previo ni hay limite de edad. Además, no estarás solo. Nuestro equipo estará a tu lado para guiarte en el proceso, ayudarte a establecer objetivos y a tener éxito.
                            </p>
                         
                    </div>
                    </div>
                    </Modal.Body>
                    <Modal.Footer style={{"justify-content": "center"}}>
                    <button className="rn-btn" onClick={this.openModal2}>Cerrar</button>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.isOpen} size="lg">
                    <Modal.Body>
                        <img src="/assets/images/dojopy/aws_certi.webp"></img>
                    </Modal.Body>
                    <Modal.Footer style={{"justify-content": "center"}}>
                    <button className="rn-btn" onClick={this.openModal}>Cerrar</button>
                    </Modal.Footer>
                </Modal>



                {/* <Modal show={this.state.isOpenTemario} size="md" onHide={this.openModalEmail}>
                <span style={{textAlign: "left", "color":"slateblue"}}> <BsArrowLeftCircle onClick={() => this.openModalEmail()} style={{ cursor: "pointer", fontSize: "39px", marginLeft: "12px", marginTop: "12px", color: "slateblue"}} /> </span>

                <h4 style={{"text-align": "center", fontWeight: "600", marginBottom: "1px"}}>Programa y Temario</h4>
                <Modal.Body>
                <div className="inner">
                    <div className="contact-form--1">
                        <div className="container" style={{maxWidth: "460px"}}>
                            <div className="row row--35 align-items-start">
                                <div className="order-2">

                                <div style={{textAlign: "-webkit-center"}} className="form-wrapper">
                                <div className="thumbnail" style={{paddingBottom: "20px"}}>
                                <img style={{boxShadow: "#424241 6px 5px 15px 0px"}} className="w-100" src="/assets/images/dojopy/aws_live_popup.webp" alt="temario aws"/>
                                </div>

                        <button onClick={this.DownloadPdf} className="rn-button-style--2 btn-solid" type="submit" name="submit" id="mc-embedded-subscribe-" style={{marginTop: '7px'}}>
                        Ver temario
                        </button>

                        <div>
                        <button onClick={() => this.openModalEmail() } className="rn-button-style--1 btn-solid" type="submit" name="submit"  style={{marginTop: '13px'}}>
                        Cerrar
                        </button>
                        </div>

                        </div>
                        </div>
                        </div>
                        </div>
                        </div>
                    </div>
                    </Modal.Body>
                </Modal> */}

        
   


    <Modal show={this.state.isOpenTemario} size="md" onHide={this.openModalEmail} style={{zIndex: "999999", alignSelf: "center"}}>

            <div style={{marginTop: "15px"}}>
                 <span style={{fontSize: "20px", "text-align": "left", fontWeight: "600", marginBottom: "1px", color: "black"}}>
                    <BsArrowLeftCircle onClick={() => this.openModalEmail()} style={{marginRight: "10px", cursor: "pointer", fontSize: "39px", marginLeft: "12px", color: "slateblue"}} />
                    Programa
                    </span>
                </div>

    <Modal.Body >
    <div className="inner">
        <div className="contact-form--1">
            <div className="container" style={{maxWidth: "370px"}}>
                <div className="row row--35 align-items-start justify-content-center">
                    <div className="order-2">

                    <div style={{textAlign: "-webkit-center"}} className="form-wrapper">
                    <div className="thumbnail" style={{paddingBottom: "8px", maxWidth: window.type_device ? "310px": "370px" }}>
                    <img style={{boxShadow: "#424241 6px 5px 15px 0px"}} className="image_temario" src="/assets/images/dojopy/aws_live_popup.webp" alt="temario bootcamp"/>
                    </div>


<form onSubmit={this.DownloadPdf}>
    <div className="row p-1">
       <div className="col-12 text-center p-1" >

       <label class="col-12" htmlFor="item02">
        <input
        type="email"
        name="email"
        id="item02"
        value={this.state.Email}
        onChange={(e) => this.setState({Email: e.target.value})}
        placeholder="¿Cuál es tu correo electrónico?"
        style={{marginBottom: "5px"}}
        />
    </label>
      

<div className="row col-12 m-auto p-1">
    <div className="col-5 p-1">
        <Selectrix
        height={300}
        onRenderOption={this.onRenderOption}
        onRenderSelection={this.onRenderSelection}
        placeHolderInside={true}
        placeholder={"País"}
        customScrollbar={true}
        searchable={false}
        materialize={false}
        defaultValue={this.state.countryCode}
        options={[
            { key: "PE", label: "(+51)" },
            { key: "MX", label: "(+52)" },
            { key: "BO", label: "(+591)" },
            { key: "CO", label: "(+57)" },
            { key: "EC", label: "(+593)" },
            { key: "PR", label: "(+1)" },
            { key: "CL", label: "(+56)" },
            { key: "PY", label: "(+595)" },
            { key: "UY", label: "(+598)" },
            { key: "US", label: "(+1)" },
            { key: "PA", label: "(+507)" },
            { key: "CR", label: "(+506)" },
            { key: "GT", label: "(+502)" },
            { key: "CU", label: "(+53)" },
            { key: "SV", label: "(+503)" },
            { key: "ES", label: "(+34)" },
            { key: "HN", label: "(+504)" },
            { key: "NI", label: "(+505)" },
            { key: "AR", label: "(+54)" },
            { key: "DO", label: "(+1)" },
            { key: "VE", label: "(+58)" },
            { key: "BR", label: "(+55)" },
        ]}
        onChange={(value) => this.DynamicCountry(value.key)}
        />
    </div>



      <label className="col-7" htmlFor="Phone" >
        <input
        type="text"
        name="phone"
        id="Phone"
        value={this.state.Phone}
        onChange={(e) => {
            this.setState({ Phone: e.target.value });
        }}
        placeholder="N.º WhatsApp"
        style={{marginBottom: "5px"}}
        />
    </label>
    
</div>

      <div className="col-12" style={{color: "white", fontSize: "14px", textAlign: "left"}}>
                    <label htmlFor="checkNotify" style={{float: "left", color: "black"}}>
                        <input
                            style={{width: 18, float: "left", marginTop: "-10px", marginRight: "9px", marginBottom: "1px"}} 
                            defaultChecked={true}
                            type="checkbox"
                            name="checkNotify"
                            id="checkNotify"
                        />
                        <span>Protección de datos y privacidad.  <FcDataProtection style={{fontSize: "20px"}} /> </span>
                    </label>
                    </div>

            <button className="rn-button-style--2 btn-solid" type="submit" name="submit" id="mc-embedded-subscribe-" style={{marginTop: '7px', fontWeight: "bold"}}>
            <MdEmail style={{fontSize:"23px"}} /> Enviar programa
            </button>
       </div>
    </div>

    </form>

                        <div>
                        <button onClick={() => this.openModalEmail() } className="rn-button-style--1 btn-solid" type="submit" name="submit"  style={{marginTop: '13px', fontSize: "16px"}}>
                        Cerrar
                        </button>
                        </div>
                   
                        </div>
               
                        </div>
                        </div>
                        </div>
                        </div>
                    </div>
                    </Modal.Body>
                </Modal>


                <Modal show={this.state.isOpenResume} size="md" onHide={this.openModal4} >
                    <span style={{textAlign: "left", "color":"slateblue"}}> <BsArrowLeftCircle onClick={() => this.openModal4()} style={{ cursor: "pointer", fontSize: "35px", marginLeft: "12px", marginTop: "12px", color: "slateblue"}} /> </span>

                    <Modal.Body>
                    <div className="">
                        <div className="section-title">
                            <h3 className="title"></h3>
                            
                            <div className="pricing-body mb-3">
                                      
                                      <ul className="list-style--1" style={{marginTop: "5px"}}>

                                        <div className="row">
                                            <div className="col-1">
                                                <span style={{"color":"slateblue", "fontSize": "22px"}}> <FiCheckCircle style={{fontSize: "20px", marginRight: "4px", color: "slateblue"}} /> </span>
                                            </div>
                                            <div className="col-10 pl-2">
                                            <p style={{fontSize: "16px", "margin-bottom": "7px", fontWeight: "bold"}}>
                                                <b style={{color: "mediumseagreen"}}>  Aprende Amazon Web Services 100% en vivo.</b> <br/>
                                            </p>
                                            </div>
                                        </div>
                                      
                                      
                                          <p style={{fontSize: "16px", "margin-bottom": "7px"}}>
                                          <span style={{marginRight: "2px", "color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "20px", marginRight: "7px", color: "slateblue"}} /> </span>
                                          Grupo reducido y personalizado. <br/>
                                          </p>
                                      
                                          <p style={{fontSize: "16px", "margin-bottom": "7px"}}>
                                          <span style={{marginRight: "2px", "color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "20px", marginRight: "7px", color: "slateblue"}} /> </span>
                                          4 semanas de entrenamiento.<br/>
                                          </p>
                                      
                                          <p style={{fontSize: "16px", "margin-bottom": "7px"}}>
                                          <span style={{marginRight: "2px", "color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "20px", marginRight: "7px", color: "slateblue"}} /> </span>
                                          También te preparamos para aprobar el examen oficial de AWS Cloud Practitioner
                                          <br/></p>
                                      
                                          <p style={{fontSize: "16px", "margin-bottom": "7px"}}>
                                          <span style={{marginRight: "2px", "color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "20px", marginRight: "7px", color: "slateblue"}} /> </span>
                                           Seguimiento y asesorías 1 a 1. <br/>
                                          </p>
                                      
                                          
                                          <p style={{fontSize: "16px", "margin-bottom": "7px"}}>
                                          <span style={{marginRight: "2px", "color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "20px", marginRight: "7px", color: "slateblue"}} /> </span>
                                          Laboratorio y prácticas en aws console<br/></p>
                                      
                                        
                                          <p style={{fontSize: "16px", "margin-bottom": "7px"}}>
                                          <span style={{marginRight: "2px", "color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "20px", marginRight: "7px", color: "slateblue"}} /> </span>
                                          Las sesiones en vivo quedan grabadas, acceso a la plataforma para siempre, para seguir practicando y aprendiendo de forma ilimitada. <br/></p>

                                      
                                          <p style={{fontSize: "16px", "margin-bottom": "7px"}}>
                                          <span style={{marginRight: "2px", "color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "20px", marginRight: "7px", color: "slateblue"}} /> </span>
                                          Proyecto final de laboratorio
                                          </p>
                                      
                                                               
                                          <p style={{fontSize: "16px", "margin-bottom": "7px"}}>
                                          <span style={{marginRight: "2px", "color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "20px", marginRight: "7px", color: "slateblue"}} /> </span>
                                          Certificado digital<br/></p>
                                 
                                    
                                      
                                          <p style={{fontSize: "16px", "margin-bottom": "7px"}}>
                                          <span style={{marginRight: "2px", "color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "20px", marginRight: "7px", color: "slateblue"}} /> </span>
                                          Acceso a la bolsa de empleos con empresas aliadas a nivel local e internacional. <br/></p>
                                      
                                      
                                      </ul>
                                      </div> 

                    </div>
                    </div>
                    </Modal.Body>
                    <Modal.Footer style={{"justify-content": "center"}}>
                        <div className={window.type_device ? "mb-5": "mb-2"}>
                        <button style={{fontSize: "18px"}} className="rn-button-style--2 btn-solid" onClick={this.openModal4}>Cerrar</button>
                        </div>
                    </Modal.Footer>
                </Modal>




    {/* Start About Area */}
    <div data-aos="fade-right" className="about-area ptb--80 " id="methodology">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">


                            {window.type_device ? (
                                        <div className="col-12" style={{textAlign: "-webkit-center", marginLeft: "-45px", marginBottom: "-40px"}}>
                                            <ReactPlayer
                                            muted={true}
                                            loop={true}
                                            url={"/assets/video/demo_lite_1.mp4"}
                                            playing={true}
                                            width={400}
                                            height={250}
                                                />
                                        </div>
                                    ):(
                                        <div className="col-12" style={{textAlign: "-webkit-center"}}>
                                        <ReactPlayer
                                        muted={true}
                                        loop={true}
                                        url={"/assets/video/demo_lite_1.mp4"}
                                        playing={true}
                                        width={520}
                                        />
                                        </div>
                                    )}




                                <div className="col-12" style={{textAlign: "-webkit-center", paddingTop: "30px"}}>
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                        {window.type_device ? (
                                            <h2>{!this.state.langUS ? "Aprende 100% online en vivo": "Learn 100% online live"} </h2>
                                        ): (
                                            <TypingAnimation2/>
                                        )}

                                        </div>

                                        <h4>
                                        {!this.state.langUS ? "En DojoFullStack proponemos un nuevo modelo educativo que incluye entornos de aprendizaje sincrónicos y asincrónicos con un enfoque que vincula la teoría y la práctica, mediante un aprendizaje activo.":
                                         "At DojoFullStack we propose a new educational model that includes synchronous and asynchronous learning environments with an approach that links theory and practice, through active learning."}

                                            </h4>

                                    <div className="row mt--30">

                                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h4 className="title"><FiCheck style={{"color": "slateblue"}}/> {!this.state.langUS ? "100% online a tu propio ritmo.": "100% online live"} </h4>
                                                </div>
                                            </div>


                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h4 className="title"><FiCheck style={{"color": "slateblue"}}/>
                                                    Retroalimentación y Tutorías 1 a 1.
                                                    </h4>

                                                </div>
                                            </div>

                                     

                                            <div className="col-12">
                                                <div className="about-us-list">
                                                    <h4 className="title"><FiCheck style={{"color": "slateblue"}}/>
                                                    {!this.state.langUS ? "Aprende, comparte y crea proyectos con miembros de todo el mundo.": "Learn, share and create projects with members from all over the world."}
                                                    </h4>
                                                </div>

                                            
                                            </div>

                                        </div>

                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>
                </div>
                {/* End About Area */}








                <PlataformaAWS img='/assets/images/dojopy/aws_plataforma.webp' />


  {/* Start Team Area  */}
  <div className="rn-team-area ptb--120 bg_color--1" id="team" data-aos="fade-right" >
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title service-style--3 text-center mb--25 mb_sm--0">
                                        <h2 className="title">Nuestro Equipo</h2>
                                        <p>Nos apasiona la tecnológica y los desafíos de este mundo cambiante.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row" style={{justifyContent: "center"}}>
                                <TeamFull column="col-4 col-md-2" />
                            </div>
                        </div>
                </div>
                {/* End Team Area  */}




    <div style={{marginTop: "70px"}} className="section-title service-style--3 text-center mb--25 mb_sm--0 text-center">
                    <h2 className="title">Testimonios</h2>
                </div>

                <div className="rn-testimonial-area bg_color--1 ptb--120">
                    <div className="container">
                        <TestimonialOne />
                    </div>
                </div>





 <ModalVideo style={{zIndex: 99999999}} channel='youtube' isOpen={this.state.VideoPitch} videoId='QRk3kqc8Tzo' onClose={() => this.setState({VideoPitch: false})} />



 <PreguntasFlex langUS={this.state.langUS} bootcampSlug={this.state.bootcampSlug} />





                <div className="footer_banner" style={{borderTopLeftRadius: "30px", borderTopRightRadius: "30px", zIndex: "99", padding: "1px", background: this.props.FooterColor }}>
                    <div className="row dojofoter" style={{
                        marginTop: !window.type_device ? "3px" : "2px",
                        marginBottom: !window.type_device ? "3px": "3px",
                        paddingLeft: !window.type_device ? "250px" : "15px",
                        paddingRight: !window.type_device ? "250px": "15px",
                        paddingTop: !window.type_device ? "7px": "3px",
                        paddingBottom: !window.type_device ? "7px": "3px"
                        }}>
<div className={"col-12 py-0 px-0 m-1"} style={{ alignItems: "center", textAlign: "center", alignSelf: "center"}} >
<a href="#informes">
                    <button
                        id="footer_info_"
                        style={{lineHeight: "25px", fontSize: window.type_device ? "23px": "25px", padding: "12px 27px" }}
                        className=" btn-efect">
                            Recibir información
                    </button>
                    </a>
                    </div>
                    </div>
                </div>





                <FooterTwo />

            </React.Fragment>
        )
    }

    async componentDidMount() {
        const btnWhatsapp = document.getElementById('whatsapp');
        btnWhatsapp.style.display = 'block';
        window.urlWasi = this.GetLinkWS();

        try {
            const response = await axios.get("https://hiuaik2gocmwdeh2ub6lqz3hxq0ycvbe.lambda-url.us-east-1.on.aws/");
            let dataPrice = response.data;
            this.setState({pricingAPI: dataPrice})
            this.DynamicCountry();

            document.getElementById('whatsapp').style.display='block';

            try {
                const responseIP = await axios.get("https://ipapi.co/json/");
                let dataPriceIP = responseIP.data;
                if (!dataPriceIP.country_code) throw "api error";
                this.setState({countryCode: dataPriceIP.country_code});
                this.DynamicCountry(dataPriceIP.country_code);
            } catch {
                axios.get('https://bdzpehnjfmdq56bcrp6gb4mcru0nxqci.lambda-url.us-east-1.on.aws/').then((response) => {
                    let data = response.data;
                    this.setState({countryCode: data.country_code});
                    this.DynamicCountry(data.country_code);
                    }).catch((error) => {
                        console.log("error api");
                    });
            }


        } catch (error) {
            console.log(error);
          }

  

    
            this.sleep(59000).then(r => {
                toast.success("Carlos y Marcos ya se inscribieron, tu tambien puedes formar parte!");
            })

            this.sleep(5000).then(r => {
                this.setState({VideoPitch: true})
            })

            
            const now_ = moment();
            const enrollMes = now_.format('MMMM');
            const enrollDay = now_.format('DD');
            this.setState({enrollMes, enrollDay });

      }
      
}
export default LandingAWS;
