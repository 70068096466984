import React, { Component } from "react";
import Header from "../component/header/Header";
import FooterTwo from "../component/footer/FooterTwo";
import { AiOutlineDown, AiOutlineRight, AiOutlineClose } from "react-icons/ai";
import { FcDataProtection } from "react-icons/fc";
import { FaWhatsapp } from "react-icons/fa";
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import Selectrix from "react-selectrix";
import ReactCountryFlag from "react-country-flag";
import 'react-tabs/style/react-tabs.css';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import { Helmet } from 'react-helmet-async';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import Modal from "react-bootstrap/Modal";
import FlipCountdown from '@rumess/react-flip-countdown';
import { toast } from 'react-toastify';



const KeyCountry =  [
    {key: "PE", label: "Perú", countryNumber: "+51"},
    {key: "MX", label: "México", countryNumber: "+52"},
    {key: "BO", label: "Bolivia", countryNumber: "+591"},
    {key: "EC", label: "Ecuador", countryNumber: "+593"},
    {key: "CO", label: "Colombia", countryNumber: "+57"},
    {key: "CL", label: "Chile", countryNumber: "+56"},
    {key: "SV", label: "El Salvador", countryNumber: "+503"},
    {key: "CR", label: "Costa Rica", countryNumber: "+506"},
    {key: "PR", label: "Puerto Rico", countryNumber: "+1"},
    {key: "HN", label: "Honduras", countryNumber: "+504"},
    {key: "UY", label: "Uruguay", countryNumber: "+598"},
    {key: "PY", label: "Paraguay", countryNumber: "+595"},
    {key: "GT", label: "Guatemala", countryNumber: "+502"},
    {key: "PA", label: "Panamá", countryNumber: "+507"},
    {key: "ES", label: "España", countryNumber: "+34"},
    {key: "NI", label: "Nicaragua", countryNumber: "+505"},
    {key: "DO", label: "República Dominicana", countryNumber: "+1"},
    {key: "BR", label: "Brasil", countryNumber: "+55"},
    {key: "US", label: "Estados Unidos", countryNumber: "+1"}
]

const initialOptions = {
    clientId: "AZeHtz9ynRf6LBFEZCIwQcl-wGC6rVlfGKTH44LBcZ9BVs-INyQQ3VpFYAFWAdX2m2GjMhZBmTQ4Dw-C",
    currency: "USD"
};



class Inscripcion extends Component{
    constructor () {
        super()
        this.state = {
            isCurso: false,
            fechaLimite: '',
            fecha_inicio: '',
            duracion: '',
            langUS: false,
            isPricePais: false,
            price_view_base : '',
            price_view_final : '',
            price_view_descuento : '',
            isFlex: false,
            unicoPago: false,
            pagoCuota: false,
            numeroCuota: 1,
            active_offer: true,
            isSuscription: false,
            is_mensual: false,
            black_friday: false,
            countryPhone: "",
            typePaymentSelect: 'card',
            loadingNext: false,
            showPaymentMetod: false,
          loadingPay: '',
          title: "",
          isOpen: false,
          select_mes: "",
          select_turno: "",
          Email: "",
          Name: "",
          LastName: "",
          Phone: "",
          select_fecha: "fecha_1",
          countryCode: 'US',
          slugBootcamp: '',
          dataApi: {},
          price_pais: "",
          price_dolares: "",
          price_payment: 0,
          pay_currency_code: 'USD',
          bootcamp: "webfullstack",
          planPago: false,
          optionPlanPago: "fullstack",
          KeysPlanes: [],
          KeysFechas: [],
          cuotas: false,
          fechasPago: [],
          showPaypal: false,
          payOption: "card",
          isModalDeposito: false,
          isFlashEspecial: false,
          FlashBody: '',
          modeIntro: false,
          fechas: [],
          activeBeca: null,
          slugOficial: "webfullstack",
          isModalBecaOff: false,
          priceConversion: null,
          garantia: false,
          porcentajeDescuento: null,
          showMore: false,
          linkPayPeru: "",
          timeCounter: "",
          showDeadLine: false
        }
        this.CreateLinkPay = this.CreateLinkPay.bind(this);
        this.GetLinkWS = this.GetLinkWS.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.changeMethodPay = this.changeMethodPay.bind(this);
        this.paypalButtonsRef = React.createRef();
    }



    
    sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }


    changeMethodPay(option, config){
        if (config !== "set"){
            this.onSubmit();
        }

        if (option === 'card'){
            this.setState({payOption: "card"});
            try {
                document.querySelector('#option-card').checked = true;
            } catch (error) {}
            document.querySelector('#modal-card').style.backgroundColor = 'ghostwhite';
            document.querySelector('#modal-card').style.border = '2px solid purple';
            document.querySelector('#modal-card').style.boxShadow = 'rgba(50, 27, 98, 0.47) 6px 5px 15px 0px';

            if (document.querySelector('#modal-paypal')){
                document.querySelector('#option-paypal').checked = false;
                document.querySelector('#modal-paypal').style.border = '1px solid #c6c9d8';
                document.querySelector('#modal-paypal').style.boxShadow = 'none';
                document.querySelector('#modal-paypal').style.backgroundColor = 'white';
            }
            if (document.querySelector('#modal-deposito')){
                document.querySelector('#option-deposito').checked = false;
                document.querySelector('#modal-deposito').style.border = '1px solid #c6c9d8';
                document.querySelector('#modal-deposito').style.boxShadow = 'none';    
                document.querySelector('#modal-deposito').style.backgroundColor = 'white';
            }
            if (document.querySelector('#modal-yape')){
                document.querySelector('#option-yape').checked = false;
                document.querySelector('#modal-yape').style.border = '1px solid #c6c9d8';
                document.querySelector('#modal-yape').style.boxShadow = 'none';    
                document.querySelector('#modal-yape').style.backgroundColor = 'white';
            }

        } else if (option === 'paypal'){
            this.setState({payOption: "paypal"});
            document.querySelector('#option-paypal').checked = true;
            document.querySelector('#modal-paypal').style.border = '2px solid purple';
            document.querySelector('#modal-paypal').style.boxShadow = 'rgba(50, 27, 98, 0.47) 6px 5px 15px 0px';
            document.querySelector('#modal-paypal').style.backgroundColor = 'ghostwhite';

            document.querySelector('#option-card').checked = false;
            document.querySelector('#modal-card').style.border = '1px solid #c6c9d8';
            document.querySelector('#modal-card').style.boxShadow = 'none';
            document.querySelector('#modal-card').style.backgroundColor = 'white';


            if (document.querySelector('#modal-deposito')){
                document.querySelector('#option-deposito').checked = false;
                document.querySelector('#modal-deposito').style.border = '1px solid #c6c9d8';
                document.querySelector('#modal-deposito').style.boxShadow = 'none';   
                document.querySelector('#modal-deposito').style.backgroundColor = 'white'; 
            }

        } else if (option === 'deposito'){
            this.setState({payOption: "deposito"});
            document.querySelector('#option-deposito').checked = true;
            document.querySelector('#option-card').checked = false;
            
            document.querySelector('#modal-deposito').style.border = '2px solid purple';
            document.querySelector('#modal-deposito').style.boxShadow = 'rgba(50, 27, 98, 0.47) 6px 5px 15px 0px';
            document.querySelector('#modal-deposito').style.backgroundColor = 'ghostwhite';

            document.querySelector('#modal-card').style.border = '1px solid #c6c9d8';
            document.querySelector('#modal-card').style.boxShadow = 'none';     
            document.querySelector('#modal-card').style.backgroundColor = 'white';  
            
            document.querySelector('#modal-yape').style.border = '1px solid #c6c9d8';
            document.querySelector('#modal-yape').style.boxShadow = 'none';    
            document.querySelector('#modal-yape').style.backgroundColor = 'white';

            // document.querySelector('#modal-paypal').style.border = '1px solid #c6c9d8';
            // document.querySelector('#modal-paypal').style.boxShadow = 'none';
            // document.querySelector('#option-paypal').checked = false;
        } else if (option === 'yape'){
            this.setState({payOption: "yape"});
            document.querySelector('#option-yape').checked = true;
            // document.querySelector('#option-card').checked = false;

            document.querySelector('#modal-yape').style.border = '2px solid purple';
            document.querySelector('#modal-yape').style.boxShadow = 'rgba(50, 27, 98, 0.47) 6px 5px 15px 0px';
            document.querySelector('#modal-yape').style.backgroundColor = 'ghostwhite';

            document.querySelector('#modal-deposito').style.border = '1px solid #c6c9d8';
            document.querySelector('#modal-deposito').style.boxShadow = 'none';     
            document.querySelector('#modal-deposito').style.backgroundColor = 'white';  


            document.querySelector('#modal-card').style.border = '1px solid #c6c9d8';
            document.querySelector('#modal-card').style.boxShadow = 'none';     
            document.querySelector('#modal-card').style.backgroundColor = 'white';  

        }

    }

    onSubmit(e) {
        try {
            e.preventDefault();            
        } catch (error) { }


        if(this.state.Email === "" ){
            toast.dark('El campo Email es requerido.');
            return;
        }
        if(this.state.Name === "" ){
            toast.dark('El campo Nombre es requerido.');
            return;
        }

        if(this.state.Phone === "" ){
            toast.dark('El campo Celular es requerido.');
            return;
        }

        // this.setState({loadingPay: true});

        localStorage.setItem('email', this.state.Email || "");
        localStorage.setItem('name', this.state.Name || "");
        localStorage.setItem('phone', this.state.Phone || "");


        axios.post('https://cprij4toee.execute-api.us-east-1.amazonaws.com/dev',
        {prospecto: true, bootcamp: this.state.slugBootcamp, plan: this.state.Plan, email: this.state.Email, name: this.state.Name, phone: this.state.Phone, country: this.state.countryCode, lastname: this.state.LastName, optionPlanPago: this.state.optionPlanPago},)
        .then(res => {
            console.log("Data Saved");

            window.fbq('track', 'Purchase', {
                value: Number(this.state.price_payment), // Reemplaza con el valor de la transacción
                currency: 'USD', // Cambia la moneda si es necesario
                content_type: 'product',
                content_ids: ['BOOTCAMP'], // Cambia con el ID del producto
                content_name: 'Bootcamp Web Full Stack' // Cambia con el nombre del producto
              });
            
            // this.setState({showPaymentMetod: true, loadingPay: false});
            // try {
            //     // document.querySelector('#option-card').checked = true;
            //     // if (this.state.countryCode !== "PE"){
            //     //     this.changeMethodPay("paypal");
            //     // } else {
            //     //     this.changeMethodPay("card");
            //     // }
            // } catch (error) {
            //     this.setState({loadingPay: false});
            //     console.log(error);
            // }
            // window.scrollTo(0, 0);

        })
    }


    GetLinkWS(){
        let texto = "Hola, tengo algunas preguntas sobre la inscripción. Me gustaría obtener más información. Gracias.";

        if (window.type_device){
            return `https://api.whatsapp.com/send?phone=51935489552&text=${texto}`;
        } else {
            return `https://web.whatsapp.com/send?phone=51935489552&text=${texto}`;
        }
    }



    onRenderOption ( option, index ) {
        return (<li key={index}>
                <ReactCountryFlag
                countryCode={option.key}
                svg
                style={{
                    width: '2.3em',
                    height: '2.3em',
                    padding: '7px'
                }}
                title="país"
        />{ option.countryNumber }</li>)
    }

    onRenderSelectionPais( selected, settings, deselect ){
        return 	(<span style={{ marginRight: 10, padding: 5 }}>
		{ selected ?
            <span style={{"color": "#1f1f25"}}>
                <ReactCountryFlag
                countryCode={selected.key}
                svg
                style={{
                    width: '2.3em',
                    height: '2.3em',
                    padding: '7px'
                }}
                title="país"
        />  {selected.countryNumber}
            </span>
            :
               <span style={{padding: 5}}>País</span>
        }
        
		<i style={{ paddingLeft: 5, cursor: "pointer" }} onClick={ deselect } className="fa fa-window-close"></i>
	</span>)
    }


    
    onRenderOptionPlan ( option, index ) {
        return (<li key={index} style={{padding: "6px", fontSize: "17px"}}>
                { option.label }
                </li>)
    }



    onRenderSelection( selected, settings, deselect ){
        return 	(<span style={{ marginRight: 10, padding: 5 }}>
		{ selected ?
            <span style={{"color": "#1f1f25", padding: "7px", fontSize: "17px"}}>
               {selected.label}
            </span>
            :
               <span style={{padding: 5}}>Seleccionar Fecha</span>
        }
		<i style={{ paddingLeft: 5, cursor: "pointer" }} onClick={ deselect } className="fa fa-window-close"></i>
	</span>)
    }


    onRenderSelectionPlan( selected, settings, deselect ){
        return 	(<span style={{ marginRight: 10, padding: 5 }}>
		{ selected ?
            <span style={{"color": "#1f1f25", padding: "10px", fontSize: "18px", fontWeight: "bold"}}>
               {selected.label}
            </span>
            :
               <span style={{padding: 5}}>Selecciona tu forma de pago</span>
        }
		<i style={{ paddingLeft: 5, cursor: "pointer" }} onClick={ deselect } className="fa fa-window-close"></i>
	</span>)
    }

 DynamicCountry(country, data, title){

    // console.log(this.state.timeCounter);


    let priceDefault = "";
    let priceMount = 0;

    priceDefault = data[title].price.usd.mount;
    priceMount = data[title].price.usd.mount;

    let price_view_base = data[title].price.usd.price_view_base;
    let price_view_final = data[title].price.usd.price_view_final;
    let price_view_descuento = data[title].price.usd.price_view_base_regular;


    this.setState({
        isPricePais: false,
        countryCode: country,
        price_dolares: priceDefault,
        price_payment: priceMount,
        pay_currency_code: 'USD',
        price_view_base,
        price_view_final,
        price_view_descuento
    })


    if (data[title].price[country]){

        let priceMountPais = data[title].price[country].mount;

        this.setState({
            isPricePais: true,
            price_payment: priceMountPais,
            price_view_base: data[title].price[country].price_view_base,
            price_view_final: data[title].price[country].price_view_final,
            price_view_descuento: data[title].price[country].price_view_base_regular,
        
        });
    }
   


    if (country !== "PE"){
        this.sleep(1000).then(r => {
            this.changeMethodPay("paypal", "set");
        })
    } else {
        this.sleep(1000).then(r => {
            this.changeMethodPay("card", "set");
        })
    }



 }






    OpenWS(){
        if (window.type_device){
            window.open("https://api.whatsapp.com/send?phone=51935489552&text=Hola, tengo algunas preguntas sobre la inscripción. Me gustaría obtener más información. Gracias." , '_blank').focus();
        } else {
            window.open("https://web.whatsapp.com/send?phone=51935489552&text=Hola, tengo algunas preguntas sobre la inscripción. Me gustaría obtener más información. Gracias." , '_blank').focus();
        }
    }

    CreateLinkPay(){

        if(this.state.Email === "" ){
            toast.dark('El campo Email es requerido.');
            return;
        }
        if(this.state.Name === "" ){
            toast.dark('El campo Nombre es requerido.');
            return;
        }

        if(this.state.Phone === "" ){
            toast.dark('El campo Celular es requerido.');
            return;
        }

        this.onSubmit();

        // if (this.state.payOption === 'deposito'){
        //     this.setState({isModalDeposito: true});
        //     return;
        // }

        // if (this.state.payOption === 'yape'){
        //     this.setState({isModalDepositoYape: true});
        //     return;
        // }


        if (this.state.countryCode.toUpperCase() === "PE"){
            window.location.assign(this.state.linkPayPeru);
        }

        return;

        let descriptionPay = this.state.title;

        descriptionPay = `${this.state.title}`;

        
        this.setState({loadingPay: true});
        axios.post('https://z8gez0imi4.execute-api.us-east-1.amazonaws.com/dev', {
            name: `${this.state.Name} ${this.state.LastName}` ,
            email: this.state.Email,
            mount: this.state.price_payment,
            description:  descriptionPay,
            country:  !this.state.langUS ? this.state.countryCode: 'US',
          }).then((response) => {
            this.setState({loadingPay: false});
            let data = response.data;
            let urlPay = data.data.payment_method.url;
            window.location.replace(urlPay);

            }).catch((error) => {
                this.setState({loadingPay: false});
                console.log(error);
            });
    }


    render(){
        return(
            <React.Fragment>

                <Helmet>
                    <title>Bootcamp Inscripción | 100% online en vivo | DojoFullStack</title>
                    <meta name="description" content="Bienvenido al bootcamp de Amazon Web Services (AWS) para desarrolladores web. Descubre cómo implementar tus aplicaciones web de forma robusta y escalable en la moderna infraestructura de AWS. Aprende a utilizar las últimas herramientas y tecnologías de AWS para crear aplicaciones web altamente efectivas y eficientes" />
                    <meta
                        property="og:description"
                        content="Bienvenido al bootcamp de Amazon Web Services (AWS) para desarrolladores web. Descubre cómo implementar tus aplicaciones web de forma robusta y escalable en la moderna infraestructura de AWS. Aprende a utilizar las últimas herramientas y tecnologías de AWS para crear aplicaciones web altamente efectivas y eficientes"
                    />
                   <meta
                    name="twitter:description"
                    content="Bienvenido al bootcamp de Amazon Web Services (AWS) para desarrolladores web. Descubre cómo implementar tus aplicaciones web de forma robusta y escalable en la moderna infraestructura de AWS. Aprende a utilizar las últimas herramientas y tecnologías de AWS para crear aplicaciones web altamente efectivas y eficientes"
                    />
                </Helmet>

            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.webp" register={true} langUS={this.state.langUS} />

            <div className="breadcrumb-area rn-bg-color bg_image bg_image--1" style={{height: "85px"}} data-black-overlay="6">
            </div>

            <div>

            {/* Start About Area */}
            <div data-aos="fade-up" className="about-area ptb--20 bg_color--5">
                    <div className="about-wrapper">
                 
                        <div className="container" style={{marginTop: "5px"}}>
                        
                     


    
  { (this.state.showDeadLine && this.state.isFlashEspecial && this.state.timeCounter !== "") && <div id="counter-deadline" className="pb-3">
        <h5 className="w-100 mt-2 mb-1 text-white text-center px-1" style={{fontSize: "17px"}}> 
            ¡Descuento Exclusivo por Tiempo Limitado! Inscríbete ahora en:
        </h5>
        <FlipCountdown
                            size={'small'}
                            yearTitle='Year'
                            dayTitle='Días'
                            hourTitle='Horas'
                            minuteTitle='Minutos'
                            secondTitle='Segs'
                            monthTitle='Mes'
                            hideYear
                            hideMonth
                            // hideDay
                            endAt={this.state.timeCounter?.toISOString()} // Date/Time
                            />
    </div>
    }


    { this.state.showDeadLine && this.state.isFlashEspecial && 
        <div className="mb-5"> </div>
    }

    <div className="row row--35 m-auto" style={{justifyContent: "space-evenly", gap: "10px"}}>
    
        <div className="col-12 col-md-6 " style={{height: '100%' , maxWidth: "450px", marginBottom: "25px", "box-shadow": "rgba(50, 27, 98, 0.47) 6px 5px 15px 0px", "border-radius": "20px", "background": "white"}}>
            <div className="about-inner inner pt-1">

                                   


{true &&
<>

    <div className="section-title mt-3 mb-1 d-flex">


    <h3 className="title mb-2" style={{paddingLeft:  window.type_device ? "1px" : "9px"}}>
        Datos de Contacto
    </h3>
    </div>


<div className="contact-form--1" style={{ paddingTop: "10px" }}>
            <form>
                <div className="row" style={{paddingLeft: !window.type_device ? "10px" : "", paddingRight: !window.type_device ? "10px" : ""}}>
                    <label class="col-12" htmlFor="item01">
                    <b style={{fontSize: "17px"}}>
                    👤 Nombre Completo:
                    </b>

                    <input
                    type="text"
                    name="name"
                    id="item01"
                    value={this.state.Name}
                    onChange={(e) => {
                        this.setState({ Name: e.target.value });
                    }}
                    placeholder="Nombre"
                    />
                </label>


                <label class="col-12" htmlFor="item02">
                     <b style={{fontSize: "17px"}}>
📧  Correo Electrónico: 
                    </b>
                    <input
                    type="email"
                    name="email"
                    id="item02"
                    value={this.state.Email}
                    onChange={(e) => {
                        this.setState({ Email: e.target.value });
                    }}
                    placeholder="ejemplo@correo.com"
                    />
                </label>
                


                <div className="text-left" >
                    <b style={{fontSize: "17px", marginLeft: "16px"}}>
                    📱 N.º de Celular:
                    </b>
                </div>


                <div className="d-flex justify-content-center mx-3">
                
                <div className="mx-2 mt-2">
                    <Selectrix
                    className="pais-inscription"
                    height={300}
                    onRenderOption={this.onRenderOption}
                    onRenderSelection={this.onRenderSelectionPais}
                    placeHolderInside={true}
                    placeholder={"País"}
                    customScrollbar={true}
                    searchable={false}
                    materialize={false}
                    defaultValue={this.state.countryCode}
                    options={KeyCountry}
                    onChange={(value) => {
                        if (this.state.Email){
                            localStorage.setItem('email', this.state.Email);
                        }
                        if (this.state.Name){
                            localStorage.setItem('name', this.state.Name);
                        }
                        if (this.state.Phone){
                            localStorage.setItem('phone', this.state.Phone);
                        }
                        
                        if (value.key){
                            window.location.href = `/inscripcion/${this.state.slugBootcamp}/${value.key}`;
                        }
                    } }
                    />

                </div>

                <div style={{width: "80%"}}>
                <label htmlFor="Phone">
                    
                    <input
                    style={{background: "white"}}
                    type="text"
                    name="phone"
                    id="Phone"
                    value={this.state.Phone}
                    onChange={(e) => {
                        this.setState({ Phone: e.target.value });
                    }}
                    placeholder="N.º Celular"
                    />
                 </label>
                </div>
              
                </div>


    


{/* {!this.state.isFlex &&
            <div className="col-12 mb-2">
                    <label>
                    <b style={{fontSize: "17px"}}>
                    Fecha de Inicio:
                    </b>
                    
                <Selectrix
                    className='planOptions'
                    height={300}
                    onRenderOption={this.onRenderOptionPlan}
                    onRenderSelection={this.onRenderSelection}
                    placeHolderInside={true}
                    placeholder={"Seleccionar Fecha"}
                    customScrollbar={true}
                    searchable={false}
                    materialize={false}
                    defaultValue={this.state.optionPlanPago}
                    options={this.state.fechas?.map((item, index) => ({"label": `${item.fecha_inicio} (Lun., Mié., Vie.)` , "key": index}) )}
                    onChange={(value) => {
                        console.log(value);
                    } }
                    />

                </label>
            </div>
    } */}







          
         
            {this.state.cuotas && this.state.fechasPago.length >= 2 &&
                <div className="col-12 mb-2">
                    <div className="mx-0 my-2"  style={{padding: "5px", border: "1px solid purple"}}>
                    <p className="m-1" style={{fontSize: "16px"}}>Fechas de pago:</p>
                    <ul className="my-0">
                        {this.state.fechasPago.map(element => (
                            <li style={{fontSize: "15px", marginTop: "5px"}}> <i>{element}
                            <span style={{color: "purple", marginLeft: "3px", display: "inline-block"}}> {this.state.price_view_base}</span>
                             </i></li>
                        ))}
                    </ul>
                    </div>
                </div>
                }

                
{!this.state.planPago &&  this.state.fechasPago.length === 0 && this.state.price_view_base &&
    <div className="col-12 mb-2">
                    <div className="mx-0 my-2"  style={{padding: "5px", border: "1px solid purple"}}>
                    <ul className="my-0">
                        <li style={{fontSize: "18px"}}> Precio Especial:
                             <span style={{color: "purple", marginLeft: "3px", display: "inline-block"}}>{this.state.price_view_base}</span>
                             
                        </li>
                    </ul>
                    </div>
    </div>
}



                </div>




            




        {/* <div className="mb-3 text-center">
            <button className="btn-efect" style={{backgroundColor: "#1f1f25", cursor: "pointer", fontFamily: "Poppins, sans-serif", width: "95%", fontSize: "25px", margin: "5px", fontWeight: "bold", textTransform: "none"}} onClick={(e) => this.onSubmit(e)}>
                {this.state.loadingPay  &&
                    <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    style={{fontSize: "20px", marginRight: "8px"}}
                    />
                }
                 Continuar
                 {!this.state.loadingPay  && <AiOutlineRight style={{marginLeft: "6px", marginBottom: "3px"}} />}
            </button>
        </div> */}


        <div className="col-12" style={{color: "white", fontSize: "14px", textAlign: "left", display: "inline-block"}}>
            <label htmlFor="checkNotify" style={{float: "left", color: "#1f1f25"}}>
                <input
                    style={{width: 15, float: "left", marginTop: "-10px", marginRight: "5px", marginBottom: "1px"}} 
                    defaultChecked={true}
                    type="checkbox"
                    name="checkNotify"
                    id="checkNotify"
                />
                <span>
                        Protección de datos y privacidad.
                    <FcDataProtection style={{fontSize: "20px"}} /> </span>
            </label>
            </div>


            </form>

        </div>
</>}


{(this.state.prueba && this.state.showPaymentMetod) &&
<div className="pt-3">
    <Alert variant={'success'}>
    <Alert.Heading>
    {`Gracias ${this.state.Name }!, pronto nos pondremos en contacto contigo para la prueba de 24 horas.`}
    <br/>
    </Alert.Heading>
    </Alert>
</div>
}


<div className="" style={{"margin": "10px", "width": "90%", "border": "1px solid #e6e6e6"}}></div>


<div className="container-pay mb-4">

    <p style={{fontSize: "18px", color: "#333"}}>
        Para confirmar tu inscripción, completa el pago aquí:
    </p>

<div className="section-title mt-3 mb-1 d-flex">
    <h3 className="title">Método de Pago</h3>
</div>

    {
    false && this.state.countryCode === "PE" &&
    <div className="mb-2">
        <span style={{fontSize: "16px"}}>Selecciona tu método de pago ideal</span>
    </div>
    }

<div className="col-12 px-0">

  

    <div className="d-flex flex-column" >

         <div id='modal-card' onClick={() => {
            this.changeMethodPay('card');
        } } style={{
            display: this.state.countryCode === 'PE' ? "flex": "none",
            cursor: "pointer", height: "115px", borderRadius: "20px", border: "2px solid purple", boxShadow: "rgba(50, 27, 98, 0.47) 6px 5px 15px 0px"}}>
            <div style={{width: "45px", alignSelf: "center", placeSelf: "center", margin: "13px", cursor: "pointer"}}>
                <input  style={{height: "20px", marginLeft: "5px", cursor: "pointer"}} type="radio" name="payment" value="card" id='option-card' />
            </div> 

            <div style={{display: "flex", alignItems: "center", maxWidth: "290px", padding: "5px", cursor: "pointer"}}>
                <label for="option-card">
                    <span style={{cursor: "pointer", fontSize: "21px", fontWeight: "bold", color: "#1f1f25", position: "relative", top: "5px"}}>
                    Tarjeta débito o crédito
                        </span>
                     <img height={'61px'}  className="ayudar" src="/assets/images/dojopy/payment-card.png" alt="métodos de pago" style={{cursor: "pointer", position: "relative", top: "-5px"}}/>
                </label>
            </div>
        </div>


        {this.state.countryCode !== 'PE' &&
        <div id='modal-paypal'  onClick={() => {this.changeMethodPay('paypal')}} className="mt-3 d-flex" style={{cursor: "pointer", height: "115px", borderRadius: "20px", border: "1px solid #c6c9d8"}}>
            <div style={{width: "45px", alignSelf: "center", placeSelf: "center", margin: window.type_device ? "4px": "13px", cursor: "pointer"}}>
                <input  style={{height: "20px", marginLeft: "5px", cursor: "pointer"}} type="radio" name="payment" value="paypalPay" id='option-paypal' />
            </div> 

            <div style={{display: "flex", alignItems: "center", maxWidth: "290px", padding: "5px", cursor: "pointer"}}>
                <label for="option-paypal">
                    <span style={{cursor: "pointer", fontSize: "21px", fontWeight: "bold", color: "#1f1f25", position: "relative", top: "7px"}}>
                        Pagar con
                    </span>
                    <img height={'65px'}  className="ayudar" src="/assets/images/dojopy/paypal_.webp" alt="métodos de pago" style={{cursor: "pointer", display: "block" }}/>

                </label>
            </div>
        </div>
        }

    {false && this.state.countryCode === 'PE' &&
        <div id='modal-deposito'  onClick={() => {this.changeMethodPay('deposito')}} className="mt-3 d-flex" style={{cursor: "pointer", height: "115px", borderRadius: "20px", border: "1px solid #c6c9d8"}}>
            <div style={{width: "45px", alignSelf: "center", placeSelf: "center", margin: window.type_device ? "4px": "13px", cursor: "pointer"}}>
                <input  style={{height: "20px", marginLeft: "5px", cursor: "pointer"}} type="radio" name="payment" value="depositoPay" id='option-deposito' />
            </div> 

            <div style={{display: "flex", alignItems: "center", maxWidth: "290px", padding: "5px", cursor: "pointer"}}>
                <label for="option-deposito">
                    <span style={{cursor: "pointer", fontSize: "21px", fontWeight: "bold", color: "#1f1f25", position: "relative", top: "3px"}}>
                    Transferencia bancaria
                    </span>
                    <div className="mt-2">
                    <img height={'25px'}  className="ayudar" src="/assets/images/dojopy/Logobcp.png" alt="métodos de pago" style={{cursor: "pointer", display: "inline", marginTop: "3px" }}/>
                    {/* <img height={'28px'}  className="ayudar" src="/assets/images/dojopy/BBVA_logo.png" alt="métodos de pago" style={{marginLeft: '16px', cursor: "pointer", display: "inline", marginTop: "3px" }}/> */}
                    </div>

                </label>
            </div>
        </div>
        }
    

    {
        false && this.state.countryCode === 'PE' &&
        <div id='modal-yape'  onClick={() => {this.changeMethodPay('yape')}} className="mt-3 d-flex" style={{cursor: "pointer", height: "115px", borderRadius: "20px", border: "1px solid #c6c9d8"}}>
            <div style={{width: "45px", alignSelf: "center", placeSelf: "center", margin: window.type_device ? "4px": "13px", cursor: "pointer"}}>
                <input  style={{height: "20px", marginLeft: "5px", cursor: "pointer"}} type="radio" name="payment" value="yapePay" id='option-yape' />
            </div> 

            <div style={{display: "flex", alignItems: "center", maxWidth: "290px", padding: "5px", cursor: "pointer"}}>
                <label for="option-yape">
                    <span style={{cursor: "pointer", fontSize: "21px", fontWeight: "bold", color: "#1f1f25", position: "relative", top: "3px"}}>
                    Paga con Yape o Plin
                    </span>
                    <div className="mt-0">
                    <img height={'57px'}  className="ayudar" src="/assets/images/dojopy/plinyape.png" alt="métodos de pago" style={{cursor: "pointer", display: "inline", marginTop: "3px" }}/>
                    </div>

                </label>
            </div>
        </div>
        }
    


        {(this.state.payOption === 'yape' || this.state.payOption === 'card' || this.state.payOption === 'deposito')  &&
        <div className="mt-3 text-center">
            <button className="btn-efect" style={{backgroundColor: "#1f1f25", fontFamily: "Poppins, sans-serif", fontSize: "25px", margin: "5px", fontWeight: "bold", textTransform: "none", cursor: "pointer"}}
                onClick={(e) => this.CreateLinkPay()}
            >
                {this.state.loadingPay  &&
                    <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    style={{fontSize: "20px", marginRight: "8px"}}
                    />
                }
                 Completar Pago
            </button>
        </div>
        }

        {this.state.payOption === 'paypal' && this.state.countryCode.toUpperCase() != 'PE' &&
        <button id="comprar-paypal-button" style={{border: "none", width: window.type_device ? "100%": "370px", marginTop: "16px", alignSelf: "center", placeSelf: "center"}}>
            <PayPalScriptProvider options={initialOptions}>
            <PayPalButtons
                style={{"layout":"horizontal"}}
                onClick={() => this.onSubmit() }
                createOrder={(data, actions) => {
                    return actions.order.create({
                        purchase_units: [
                            {
                                amount: {
                                    currency_code: this.state.pay_currency_code,
                                    value: this.state.price_payment,
                                },
                            },
                        ],
                    });
                }}
                onApprove={(data, actions) => {
                    return actions.order.capture().then((details) => {
                        const name = details.payer.name.given_name;
                        // console.log(`Transaction completed by ${name}`);
                        window.location.href = '/';
                    });
                }}
            />
            </PayPalScriptProvider>
        </button>
        }

    </div>

</div>

</div>

        </div>
    </div>











<div className="col-12 col-md-6  text-white" style={{boxShadow: "rgb(50 27 98 / 47%) 6px 5px 15px 0px", borderRadius: "20px", background: "linear-gradient(to right, #8e2de2, #4a00e0)", color: "white", marginTop: window.type_device ? "10px": "1px","align-self": "flex-start", "text-align": "center", maxWidth: "500px"}}>
<div className="row mt-3 mb-4 text-white" style={{"justify-content": "center"}}>

<div className="pt-1 pb-1 text-white px-1">
    <h3 style={{color: "white"}} className={`${window.type_device ? "px-5" : "px-1"}`}>
        {this.state.title}
    </h3>

  

    { this.state.cuotas &&  <h4 style={{color: "white"}}>(1er. Mes)</h4> }



    {this.state.black_friday && <h6 style={{color: "gold"}}> {this.state.black_friday} </h6>}
    



</div>

<ul className="list-dojopy" style={{fontSize: "17px", textAlign: "left", color: "white"}}>

{this.state.isCurso &&
                <>
                <li> <b>Duración: {this.state.duracion}</b> </li>
                <li>Acceso para siempre al proyecto (incluye actualizaciones)</li>
                <li>Completa el programa a tu propio ritmo.</li>
                <li>Acceso ilimitado a la plataforma de forma permanente.</li>
                <li>Apoyo profesional durante el programa.</li>
                <li>Comunidad activa para compartir y aprender.</li>
                <li>¡No pierdas esta oportunidad única de mejorar tus habilidades y destacar en el mercado laboral!</li>
                <li>Certificación digital.</li>
                </>
    }

        {this.state.isFlex &&
                <>
                <li>Completa el Bootcamp a tu propio ritmo.</li>

                {!this.state.showMore &&
                            <button
                                                onClick={() => this.setState({showMore: true}) }
                                                type="submit"
                                                name="submit"
                                                id="mc-embedded-subscribe-"
                                                style={{ marginTop: "5px", marginBottom: "5px", fontSize: "18px", color: "white",
                                                    textAlign: "center",
                                                "border-radius": "20px",
                                                "border": "1px solid white",
                                                "padding": "7px 13px",
                                                width: "90%"

                                            }}
                                                >
                                                 Mostrar más
                                                <AiOutlineDown style={{padding: "5px", fontSize: "28px"}}/>
                                                </button>
                }

                {this.state.showMore &&
                    <>
                <li>Grupo privado de WhatsApp.</li>
                <li>Comunidad activa para compartir y aprender.</li>
                <li>Acceso de por vida a la Plataforma.</li>
                <li>Certificación digital.</li>
                <li>Programa de preparación profesional.</li>
                <li>¡No pierdas esta oportunidad única de mejorar tus habilidades y destacar en el mercado laboral!</li>
                </>
    }
     </>
}
        {(!this.state.isFlex && !this.state.isCurso) &&
                    <>
                        {this.state.duracion &&
                        <li>
                            <b>Duración:</b>
                            <b style={{fontSize: "17px", marginLeft: "5px"}}>{this.state.duracion} </b> 
                        </li>
                        }
                     
                        <li> <b style={{fontSize: "17px"}}>Sesiones 100% online
                                                            <span style={{lineHeight: "21px", display: 'inline-block', padding: "3px",marginLeft: "7px", "background-color": "tomato", "border-radius": "7px", "padding-left": "7px", "padding-right": "7px", "color": "white", "font-size": "16px"}}>
                                                            <span class="pulsar"></span>  En vivo
                                                            </span>

                            </b></li>
                    
                         <li>Programa completo diseñado para impulsar tu carrera profesional.</li> 
                        
                         {!this.state.showMore &&
                            <button
                                                onClick={() => this.setState({showMore: true}) }
                                                type="submit"
                                                name="submit"
                                                id="mc-embedded-subscribe-"
                                                style={{ marginTop: "5px", marginBottom: "5px", fontSize: "18px", color: "white",
                                                    textAlign: "center",
                                                "border-radius": "20px",
                                                "border": "1px solid white",
                                                "padding": "7px 13px",
                                                width: "90%"

                                            }}
                                                >
                                                 Mostrar más
                                                <AiOutlineDown style={{padding: "5px", fontSize: "28px"}}/>
                                                </button>
                        }


                        {this.state.showMore &&
                        <>
                            <li>Apoyo profesional durante todo el Bootcamp.</li>
                            <li>Mentorías personalizadas. </li>
                            <li>Comunidad activa y soporte profesional.</li>
                            <li>Certificación digital.</li>
                            <li>Acceso ilimitado a la plataforma.</li> 
                            <li>Acceso al programa de empleabilidad.</li>
                            <li>
                            Garantía de empleabilidad en Tech.
                        </li>
                            <li>Realiza actividades y proyectos cada semana para poner en práctica todo lo aprendido.
                                ¡No pierdas esta oportunidad única de mejorar tus habilidades y destacar en el mercado laboral!
                            </li>
                        </>
                        }

                    </>
        }

</ul>


   
<div>



</div>
 
    <div className="col-10" style={{"margin": "20px", "width": "100%", "border": "1px solid #ffffff85"}}></div>

    <div className="col-7">
        <h4 style={{fontSize: "1.4rem", color: "white"}}>  Precio regular  </h4>
    </div>

    <div className="col-5 px-1">
        <h4 style={{fontSize: "1.6rem", color: "tomato", fontWeight: "normal", marginBottom :"7px"}}>
            <del style={{color: "tomato"}}>{this.state.price_view_descuento} </del>
        </h4>
    </div>


    <div className="col-12 px-1">
           <b style={{fontSize: "17px"}}>
            <span style={{display: "inline-flex", padding: "6px 10px", margin: "6px", "background-color": "tomato", "border-radius": "7px", "color": "white", "font-size": "17px", fontWeight: "bold"}}>
            {this.state.porcentajeDescuento}
            </span>
            </b>
    </div>




    <div className="col-7 mt-2">
        <h4 style={{fontSize: "1.4rem", color: "white"}}>  Paga Únicamente   </h4>
    </div>

    <div className="col-5 px-1 mt-2" style={{placeSelf: "center"}}>
        <h4 style={{fontSize: "1.7rem", color: "white"}}>
            {this.state.price_view_base}
        </h4>
    </div>

    <div className="p-2">
        <p style={{fontSize: "15px", marginTop: "3px", color: "white"}}>
       {this.state.isFlashEspecial ?
         "¡Inscríbete hoy y ahorra un 50% + Descuento Exclusivo! No dejes pasar esta oportunidad única para beneficiarte." :
         "¡Inscríbete hoy y ahorra un 50%! No dejes pasar esta oportunidad única para beneficiarte."}
        </p>
    </div>

    

    <div className="p-2 text-left mt-3 text-white" style={{marginLeft:  window.type_device ? "10px" : "5px"}}>

        {/* <p>El precio es en dólares americanos (USD) y te los mostramos en tu moneda local
             para facilitarte la compra del programa.
        </p> */}
    <h4 className="text-white py-0">
        {!this.state.langUS ? "Formas de pago: ": "Payment Methods:"}
    </h4>
    <p className="text-white">
        {!this.state.langUS ? "Paga con tarjeta de débito, crédito, transferencia bancaria.": "Pay with debit card, credit, transfer or cash."}
         <br/>
    {this.state.countryCode !== 'PE' &&  this.state.countryCode !== 'MX' &&  this.state.countryCode !== 'BO' &&
        " El precio es en dólares americanos (USD)" }
    </p>


    <div style={{marginTop: "10px"}} className="section-title service-style--3 text-left mb--20 mb_sm--0 ">
<img width="250px" className="ayudar" src="/assets/images/dojopy/payment-card.png" alt="métodos de pago"/>

{this.state.countryCode === 'PE' &&
    <img height={'55px'}  className="ayudar" src="/assets/images/dojopy/plinyape.png" alt="métodos de pago" style={{display: "block", marginTop: "3px" }}/>
}
</div>


    </div>
</div>
</div>


<div style={{marginTop: "50px"}} className="section-title service-style--3 text-left">
                        <h5 className="title text-black">
                            Después de completar el pago:
                            </h5>
                        <p>Enviar el comprobante de pago al correo
                            <a href="mailto:inscripciones@dojofullstack.com?subject=Comprobante de pago"><b> inscripciones@dojofullstack.com </b></a>
                                o por <a target="blank" href="https://api.whatsapp.com/send?phone=51935489552&text=Hola, tengo algunas preguntas sobre la inscripción. Me gustaría obtener más información. Gracias."> WhatsApp</a>  para confirmar la inscripción.
                        </p>


                        <h5 className="title text-black">
                        Una vez verificado el pago por nuestro equipo de soporte:
                            </h5>
                        <p>
                            <ul>
                                <li>
                                Obtendrás acceso inmediato a la plataforma del Bootcamp Full Stack.
                                </li>
                                <li>
                                Obtendrás acceso inmediato al grupo privado de WhatsApp con los demás miembros del bootcamp.
                                </li>
                                <li>
                                Serás contactado por WhatsApp o por correo electrónico por un asesor personalizado.
                                </li>
                            </ul>

                        
                             </p>



                        <p className="mt-4">
                        Si tienes alguna duda, escríbenos por WhatsApp o correo electrónico.
                        </p>

                        <div className="slide-btn text-black" style={{marginTop: "5px"}}>
                            <button value="" style={{textAlignLast: "center", fontSize: "15px"}} className="text-black rn-button-style--2 btn-primary-color-ws" onClick={this.OpenWS}>
                            <FaWhatsapp style={{fontSize: "20px", marginRight: "10px"}}/>
                            ¿Necesitas ayuda? 
                            </button>
                        </div>
                    </div>

                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area */}









 
</div>

            <Modal show={this.state.isModalDeposito} size="lg" onHide={() => this.setState({isModalDeposito: false})} style={{zIndex: "9999999999"}} >
                    <Modal.Body>
                    <div className="about-inner inner">
                        <div className="section-title mt-1 mb-1">
                           
                    <div style={{padding: "5px"}}>
            
                    <img height={'33px'}  className="ayudar" src="/assets/images/dojopy/Logobcp.png" alt="métodos de pago" style={{display: "block", marginTop: "3px" }}/>
                    

                    <p className="my-0"  style={{paddingTop: '9px', fontWeight: "bold"}}>N.º Cuenta BCP:
                    <br/>
                        <span style={{display: 'block'}}> <b style={{fontSize: "23px"}}> <mark> 19191416080072 </mark></b></span>
                    </p>

                    <p className="my-0"  style={{paddingTop: '9px', fontWeight: "bold"}}>N.º CCI:
                    <br/>
                        <span style={{display: 'block'}}> <b style={{fontSize: "23px"}}> <mark> 00219119141608007259 </mark></b></span>
                    </p>


                    <p className="mt-2 pt-0">Titular: <b style={{fontSize: "18px", paddingLeft: '1px'}}>HENRY VASQUEZ CONDE</b>  </p>


                    
                    {/* <div className="mt-3" style={{borderTop: '1px solid gray', paddingTop: '7px'}}>
                        <img height={'35px'}  className="ayudar" src="/assets/images/dojopy/BBVA_logo.png" alt="métodos de pago" style={{display: "block", marginTop: "3px" }}/>
                        <p className="my-0" style={{paddingTop: '9px', fontWeight: "bold"}}>N.º Cuenta BBVA:
                            <span style={{display: 'block'}}> <b style={{fontSize: "25px"}}> <mark> 0011-0842-0200257241 </mark></b></span>
                        </p>
                        <p className="mt-1 pt-0">Titular: <b style={{fontSize: "18px",  paddingLeft: '1px'}}>HENRY VASQUEZ CONDE</b>  </p>
                    </div> */}
              

                    <div className="mt-3" style={{borderTop: '1px solid gray', paddingTop: '7px'}}>
                        <img height={'50px'}  className="ayudar" src="/assets/images/dojopy/plinyape.png" alt="métodos de pago" style={{display: "block", marginTop: "3px" }}/>
                        <p className="my-0" style={{display: "inline-block", paddingTop: '9px', fontWeight: "bold"}}>N.º
                            <span> <b style={{fontSize: "18px", marginLeft: '5px'}}> 935 489 552 </b></span>
                        </p>
                     
                        <p className="mt-1 pt-0">Titular: <b style={{fontSize: "17px"}}>HENRY VASQUEZ CONDE</b>  </p>
                    </div>
              


                    <div style={{marginTop: "23px"}} className="section-title service-style--3 text-left">
                        <h5 className="title text-black my-0">
                            Después de completar el pago:
                            </h5>
                        <p>Enviar el comprobante de pago al correo
                            <a href="mailto:inscripciones@dojofullstack.com?subject=Comprobante de pago"><b> inscripciones@dojofullstack.com </b></a>
                                o por <a target="blank" href="https://api.whatsapp.com/send?phone=51935489552&text=Hola, tengo algunas preguntas sobre la inscripción. Me gustaría obtener más información. Gracias."> WhatsApp </a> para confirmar la inscripción.
                        </p>
                        <p>
                        Escríbenos por WhatsApp o Email si necesita ayuda.
                        </p>

                        <div className="slide-btn text-black" style={{marginTop: "5px"}}>
                            <button value="" style={{textAlignLast: "center", fontSize: "15px"}} className="text-black rn-button-style--2 btn-primary-color-ws" onClick={this.OpenWS}>
                            <FaWhatsapp style={{fontSize: "20px", marginRight: "10px"}}/>
                            ¿Necesitas ayuda? 
                            </button>
                        </div>
                    </div>


            </div>
                    </div>
                    </div>
                    </Modal.Body>

                </Modal>



                <Modal show={this.state.isModalDepositoYape} size="lg" onHide={() => this.setState({isModalDepositoYape: false})} style={{zIndex: "9999999999"}} >
                    <Modal.Body>
                    <div className="about-inner inner">
                        <div className="section-title mb-1">
                           
                    <div style={{padding: "5px"}}>
            
                    <div className="mt-3 mb-2">
                        <img height={'70px'}  className="ayudar" src="/assets/images/dojopy/plinyape.png" alt="métodos de pago" style={{display: "block", marginTop: "3px" }}/>
                        <p className="my-0" style={{display: "inline-block", paddingTop: '9px', fontWeight: "bold", backgroundColor: "yellow"}}>N.º
                            <span> <b style={{fontSize: "23px", marginLeft: '5px'}}> 935 489 552 </b></span>
                        </p>
                     
                        <p className="mt-1 pt-0">Titular: <b style={{fontSize: "19px"}}>HENRY VASQUEZ CONDE</b>  </p>
                    </div>


                    <div className="my-2 d-flex" style={{justifyContent: "center", alignItems: "center", gap: "30px", flexDirection: window.type_device ? "column": "row"}}>

                    <img width={'100%'}  className="ayudar mx-1" src="/assets/images/dojopy/pay_yape.png" alt="métodos de pago" style={{display: "inline-block", maxWidth: "250px", borderRadius: "20px" }}/>
                    
                    <img width={'100%'}  className="ayudar mx-1" src="/assets/images/dojopy/pay_plin.png" alt="métodos de pago" style={{display: "inline-block", maxWidth: "250px", borderRadius: "20px" }}/>

                    </div>
              


                    <div style={{marginTop: "25px"}} className="section-title service-style--3 text-left">
                        <h5 className="title text-black">
                            Después de completar el pago:
                            </h5>
                        <p>Enviar el comprobante de pago al correo
                            <a href="mailto:inscripciones@dojofullstack.com?subject=Comprobante de pago"><b> inscripciones@dojofullstack.com </b></a>
                                o por <a target="blank" href="https://api.whatsapp.com/send?phone=51935489552&text=Hola, tengo algunas preguntas sobre la inscripción. Me gustaría obtener más información. Gracias."> WhatsApp </a> para confirmar la inscripción.
                        </p>
                        <p>
                        Escríbenos por WhatsApp o Email si necesita ayuda.
                        </p>

                        <div className="slide-btn text-black" style={{marginTop: "5px"}}>
                            <button value="" style={{textAlignLast: "center", fontSize: "15px"}} className="text-black rn-button-style--2 btn-primary-color-ws" onClick={this.OpenWS}>
                            <FaWhatsapp style={{fontSize: "20px", marginRight: "10px"}}/>
                            ¿Necesitas ayuda? 
                            </button>
                        </div>
                    </div>


            </div>
                    </div>
                    </div>
                    </Modal.Body>

                </Modal>




<Modal show={this.state.isModalBecaOff} size={window.type_device ? "md": "lg"} onHide={() => this.setState({isModalBecaOff: true})} style={{zIndex: "9999999999"}} >
<Modal.Body>
<div className="about-inner inner">
    <div className="section-title">
      <h3 className="mt-3 p-2 text-black" style={{lineHeight: "35px"}}>¡La Beca y Descuento ha Expirado! ⏳</h3>
        <p className="p-1 text-black">
        ¡Lo sentimos! El tiempo para aplicar a la beca y descuento ha expirado. <br/>
        Te animamos a estar atento a futuras oportunidades. ¡Gracias! <br/>
        </p>

        <p className="p-1 text-black">
            <mark>
            El costo actual es: <b>  {this.state.price_view_descuento}</b>
            </mark>
        </p>


        <div className="w-100 m-1">
          <a  href={`/inscripcion/${this.state.slugOficial}/${this.state.countryCode}`}> 
          <button className="btn-efect">
          Inscríbete Aquí
            </button>
          </a>
        </div>

        <div className="mt-3">
        <p className="p-1 text-black">
            <b>
            ¡Contáctanos por WhatsApp para evaluar la posibilidad de obtener una beca y descuento especial! <br/>
            </b>
        <a  target="_blank" href={"https://api.whatsapp.com/send?phone=51935489552&text=Hola%2C%20informes%20sobre%20la%20beca%20del%20Bootcamp%20Full%20Stack."}> 
          <button className="btn-efect-live">
          Contáctanos Aquí
            </button>
          </a>
        </p>
        </div>

</div>
</div>
</Modal.Body>
</Modal>


                <FooterTwo langUS={this.state.langUS}  />

            </React.Fragment>
        )
    }



    runtDiscountHot(country, isFlex){
        console.log("module runDiscountFlash!");

        const getCookieValue = (name) => {
            const value = `; ${document.cookie}`;
            const parts = value.split(`; ${name}=`);
            if (parts.length === 2) return parts.pop().split(';').shift();
        }

        const discount60mValue = getCookieValue("discount60m");
        const discount24hValue = getCookieValue("discount24h");

        if (discount60mValue){
            console.log("DESCUENTO ACTIVO!");
            const discount60mDate = new Date(parseInt(discount60mValue, 10));
            // console.log(discount60mDate);
            this.setState({timeCounter: discount60mDate})
            return;
        }


        if ( !discount60mValue && !discount24hValue){
            console.log("CREANDO DESCUENTO!");

            const fechaActual = new Date();
            const fechaActual2 = new Date();
    
            const minutes = 60;
            const houresReboot = 24;
    
            fechaActual.setTime(fechaActual.getTime() +  (minutes * 60 * 1000) );
            fechaActual2.setTime(fechaActual2.getTime() +  (houresReboot * 60 * 60 * 1000) );
    
            document.cookie = `discount60m=${fechaActual.getTime()};expires=${fechaActual.toUTCString() }`;
            document.cookie = `discount24h=${fechaActual2.getTime()};expires=${fechaActual2.toUTCString() }`;
            this.setState({timeCounter: fechaActual})
        } else if (!discount60mValue && discount24hValue){
            console.log("FINALIZO EL DESCUENTO!");
            
            if (!isFlex){
                window.location.href = `/inscripcion/fullstack/${country || "code"}`;
            } else {
                window.location.href = `/inscripcion/flex/${country || "code"}`;
            }


        }


       
    }



    async componentDidMount() {

        window.urlWasi = this.GetLinkWS();

        let country = this.props.match.params.country.toUpperCase();
        const slugBootcamp = this.props.match.params.bootcamp.toLowerCase();

        if (country == 'CODE'){
            axios.get('https://ipapi.co/json/').then((response) => {
                let data = response.data;
                window.location.href = `/inscripcion/${slugBootcamp}/${data.country}`;
            }).catch((error) => {
                console.log(error);
            });
        }


        this.setState(
            {
                countryCode: country,
                slugBootcamp: slugBootcamp,
                optionPlanPago: slugBootcamp,
                }
        );

        const email = localStorage.getItem('email');
        const name = localStorage.getItem('name');
        const phone = localStorage.getItem('phone');

        if (email){
            this.setState({Email: email});
        }
        if (name){
            this.setState({Name: name});
        }
        if (phone){
            this.setState({Phone: phone});
        }
     

        axios.get('https://hiuaik2gocmwdeh2ub6lqz3hxq0ycvbe.lambda-url.us-east-1.on.aws/').then((response) => {
            const dataApi = response.data;

            try {
                const title = dataApi[slugBootcamp].title;
                const unicoPago = dataApi[slugBootcamp].unico_pago;
                const pagoCuota = dataApi[slugBootcamp].cuota;
                const planPago = dataApi[slugBootcamp].planPago || false;
                const fecha_inicio = dataApi[slugBootcamp].fecha_inicio || "";
                const duracion = dataApi[slugBootcamp].duracion || "";

                const isFlex = dataApi[slugBootcamp].is_flex || false;
                const isCurso = dataApi[slugBootcamp].isCurso;

                const slugPlanes = dataApi[slugBootcamp].slugPlanes;
                const KeysPlanes = dataApi['bootcamp-planes'][slugPlanes];
                const cuotas = dataApi[slugBootcamp].cuotas || false;
                const fechasPago = dataApi[slugBootcamp].fechasPago || [];
                const fechas = dataApi[slugBootcamp].fechas || [];

                const fechaLimite = dataApi[slugBootcamp].fechaLimite || '';
                const isFlashEspecial = dataApi[slugBootcamp].isFlashEspecial || false;
                const FlashBody = dataApi[slugBootcamp].FlashBody || '';
                const modeIntro = dataApi[slugBootcamp].modeIntro || '';

                const activeBeca = dataApi[slugBootcamp].activeBeca;
                const slugOficial = dataApi[slugBootcamp].slugOficial || '';
                const priceConversion = dataApi[slugBootcamp].priceConversion || '';
                const garantia = dataApi[slugBootcamp].garantia || '';
                const porcentajeDescuento = dataApi[slugBootcamp]?.porcentajeDescuento || '';
                const linkPayPeru = dataApi[slugBootcamp].linkPayPeru || '';

                    
                if (isFlashEspecial) {
                    this.runtDiscountHot(country, isFlex);

                    const timer = setTimeout(() => {
                        this.setState({ showDeadLine: true });
                      }, 3000); // 3 segundos

                }


                this.setState({fechas:fechas, isCurso: isCurso, pagoCuota: pagoCuota, unicoPago: unicoPago, title: title, dataApi: dataApi,
                    isFlex: isFlex, planPago: planPago, fecha_inicio: fecha_inicio, KeysPlanes: KeysPlanes, cuotas: cuotas,
                    fechasPago: fechasPago,
                    duracion,
                    fechaLimite,
                    isFlashEspecial,
                    FlashBody,
                    modeIntro,
                    activeBeca,
                    slugOficial,
                    priceConversion,
                    garantia,
                    porcentajeDescuento,
                    linkPayPeru
                });
                this.DynamicCountry(country, dataApi, slugBootcamp);
                
                document.getElementById('whatsapp').style.display='block';


            } catch (error) {
                console.log(error);
                window.location.href = '/';
            }
            

            }).catch((error) => {
                console.log(error);
            });




      }

}
export default Inscripcion;
